import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {Card, CardBody, Col, Media, Nav, NavItem, NavLink, Row} from "reactstrap";
import Registered from "./Registered";
import CommittedAlgos from "./CommittedAlgos";
import AlgosReward from "./AlgosReward";
import Status from "./Status";
import SimpleBar from "simplebar-react";
import useFetchData from "../../../data/governance/useFetchData";
import {formatDateWithTime, formatDateWithTimeLong} from "../../../helpers/formatter";
import classnames from "classnames";
import VotingTopic from "../Voting/VotingTopic";


const Account = (props) => {
    const [accountMessages, setAccountMessages] = useState([]);
    const [accountActivityHistory, setAccountActivityHistory] = useState({});
    const [accountInfo, setAccountInfo] = useState({});
    const [accountActivityHistoryLoading, setAccountActivityHistoryLoading] = useState(true);

    const [activeTab, setActiveTab] = useState(0)

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const getIconType = index => {
        if (index === 1) {
            return "mdi mdi-check"
        }
        else if (index === 2) {
            return "mdi mdi-sync"
        }
        return "mdi mdi-information-variant"
    }

    const getClassNameType = index => {
        if (index === 1) {
            return "avatar-title rounded-circle bg-success bg-soft text-success font-size-18"
        }
        else if (index === 2) {
            return "avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18"
        }
        else if (index === 3) {
            return "avatar-title rounded-circle bg-danger bg-soft text-danger font-size-18"
        }
        return "avatar-title rounded-circle bg-warning bg-soft text-warning font-size-18"
    }

    const getTitle = str => {
        if (str === "committed") {
            return "Registered";
        }
        else if (str === "commit-changed") {
            return "Commitment changed";
        }
        else if (str === "voted") {
            return "Voted";
        }
        else if (str === "vote-changed") {
            return "Vote changed";
        }
        else if (str === "commit-failed") {
            return "Commitment failed";
        }
        else if (str === "vote-ineligible") {
            return "Vote ineligible";
        }
        else if (str === "did-not-vote") {
            return "Forgot to vote";
        }
        return str;
    }

    const getType = str => {
        if (str === "committed" || str === "voted") {
            return 1;
        }
        else if (str === "commit-changed" || str === "vote-changed") {
            return 2;
        }
        else if (str === "commit-failed" || str === "vote-ineligible" || str === "did-not-vote") {
            return 3;
        }
        return 0;
    }


    useEffect(() => {
        if (props.foundationPeriod.loading || props.addressData["address"] === undefined) return;

        const fetchData = async () => {
            setAccountActivityHistoryLoading(true);
            try {
                const response1 = await fetch("https://governance.algorand.foundation/api/periods/" + props.foundationPeriod.data.slug + "/governors/" + props.addressData["address"]);
                const jsonData1 = await response1.json();
                const response = await fetch("https://governance.algorand.foundation/api/periods/" + props.foundationPeriod.data.slug + "/governors/" + props.addressData["address"] + "/activities/");
                const jsonData = await response.json();
                setAccountInfo(jsonData1);
                setAccountActivityHistory(jsonData.results);
            } catch (error) {
                console.error(error)
                return {};
            }
            setAccountActivityHistoryLoading(false);
        };
        fetchData();

    }, [props.foundationPeriod.loading, props.addressData["address"]]);

    useEffect(() => {

        if (accountActivityHistoryLoading) return;

        let newMessages = [];
        for (let i = 0; i < accountActivityHistory.length; ++i) {
            accountActivityHistory[i]["type"] = getType(accountActivityHistory[i].activity_type);
            accountActivityHistory[i]["title"] = getTitle(accountActivityHistory[i].activity_type);
            newMessages.push(accountActivityHistory[i]);
        }

        setAccountMessages(newMessages);

    }, [accountActivityHistoryLoading]);

    return (
        <React.Fragment>
            {props.addressData.remote === 1 && (
                <>
                    <Row>
                        <Col xl="3" md="6">
                            <Status addressData={props.addressData}/>
                        </Col>
                        <Col xl="3" md="6">
                            <Registered addressData={props.addressData}/>
                        </Col>
                        <Col xl="3" md="6">
                            <CommittedAlgos addressData={props.addressData}/>
                        </Col>
                        <Col xl="3" md="6">
                            <AlgosReward addressData={props.addressData} foundationPeriod={props.foundationPeriod}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="6" md="6">
                            <Card>
                                <CardBody>
                                    <p className="text-muted mb-4">
                                        <i
                                            className="mdi mdi-history h2 text-secondary align-middle mb-0 me-3"
                                        />{" "}
                                        Activity History
                                    </p>
                                    <SimpleBar style={{maxHeight: "425px"}}>
                                        <ul className="list-group">
                                            {!accountActivityHistoryLoading && (
                                                <>
                                                    {accountMessages.map((item, i) => (
                                                        <li className="list-group-item border-0" key={"pl" + i}>
                                                            <Media>
                                                                <div className="avatar-xs me-3">
                                                                  <span
                                                                      className={getClassNameType(item.type)}>
                                                                    <i className={getIconType(item.type)}/>
                                                                  </span>
                                                                </div>
                                                                <Media body>
                                                                    <h5 className="font-size-14">{item.title}</h5>
                                                                    <p className="text-muted">
                                                                        {item.message}
                                                                    </p>

                                                                    <div className="float-end">
                                                                        <p className={item.transaction == null ? "d-none"  : "text-muted mb-0"}>
                                                                            <a
                                                                                href={" https://algoexplorer.io/tx/" + (item.transaction === null ? "" : item.transaction.transaction_id)}>
                                                                                AlgoExplorer <i
                                                                                className="mdi mdi-arrow-top-right"></i>
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                    <p className="text-muted mb-0">{formatDateWithTimeLong(new Date(item.creation_datetime))}</p>
                                                                </Media>
                                                            </Media>
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                        </ul>
                                    </SimpleBar>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="6" md="6">
                            <Card>
                                <CardBody>
                                    <p className="text-muted mb-4">
                                        <i
                                            className="mdi mdi-ab-testing h2 text-primary align-middle mb-0 me-3"
                                        />{" "}
                                        Voting
                                    </p>
                                    {!accountActivityHistoryLoading &&  (
                                        <>
                                        <Nav pills className="bg-low rounded">
                                            {accountInfo.voting_session_history.map((item, i) => (
                                                <NavItem key={"navaccount" + i}>
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTab == i,
                                                        })}
                                                        onClick={() => {
                                                            toggleTab(i)
                                                        }}
                                                    >
                                                        Voting #{i + 1}
                                                    </NavLink>
                                                </NavItem>
                                                ))}
                                        </Nav>
                                        {accountInfo.voting_session_history.map((item, i) => (
                                            <div key={"bodyaccount"+i} className={activeTab == i ? "" : "collapse1"}>
                                                <p className="text-muted mt-4">
                                                    <h5>{item.title}</h5>
                                                </p>
                                                <SimpleBar style={{maxHeight: "330px"}}>
                                                    {item.votes.map((measure, j) => (
                                                           <>
                                                               <hr></hr>
                                                               <h6>Measure #{j + 1} - {measure.topic.title}</h6>
                                                                <div>
                                                                   <ul className="list-group list-group-flush">
                                                                       {measure.voted_options.map((voted_options, k) => (
                                                                           <>
                                                                               {voted_options.allocated_algo_amount > 0 && (
                                                                                   <li className="list-group-item">
                                                                                       <div>
                                                                                           <h5 className="font-size-14">
                                                                                               {voted_options.voted_option.indicator.toUpperCase()} - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(voted_options.allocated_algo_amount / 1000000)}
                                                                                               <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format((voted_options.allocated_algo_amount * 100) / (props.addressData["committed_algo_amount"] + props.addressData["committed_assets_amount_in_algo"]))}%</span>
                                                                                           </h5>

                                                                                           <div className="progress animated-progess progress-sm">
                                                                                               <div
                                                                                                   className={voted_options.voted_option.indicator == "a" ? "progress-bar" : "progress-bar bg-success"}
                                                                                                   role="progressbar"
                                                                                                   style={{ width: (voted_options.allocated_algo_amount * 100) / (props.addressData["committed_algo_amount"] + props.addressData["committed_assets_amount_in_algo"]) + "%" }}
                                                                                                   aria-valuenow={(voted_options.allocated_algo_amount * 100) / (props.addressData["committed_algo_amount"] + props.addressData["committed_assets_amount_in_algo"])}
                                                                                                   aria-valuemin="0"
                                                                                                   aria-valuemax="100"
                                                                                               ></div>
                                                                                           </div>
                                                                                       </div>
                                                                                   </li>
                                                                               )}
                                                                           </>
                                                                       ))}
                                                                   </ul>
                                                                </div>
                                                           </>
                                                        ))}
                                                </SimpleBar>
                                            </div>
                                            ))}
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="3" md="6">
                            <Card>
                                <CardBody>
                                    <p className="text-muted mb-4">
                                        <i
                                            className="mdi mdi-shape h2 text-info align-middle mb-0 me-3"
                                        />{" "}
                                        Account links
                                    </p>
                                    <div className="m-3">
                                        <h6><a
                                            href={"https://governance.algorand.foundation/" + props.foundationPeriod.data.slug + "/governors/" + props.addressData["address"]}>
                                            AlgorandFoundation <i className="mdi mdi-arrow-top-right"></i>
                                        </a></h6>
                                        <h6><a href={"https://algoexplorer.io/address/" + props.addressData["address"]}>
                                            AlgoExplorer <i className="mdi mdi-arrow-top-right"></i>
                                        </a></h6>
                                        <h6>
                                            <a href={"https://www.asastats.com/" + props.addressData["address"]}>
                                                Asastats <i className="mdi mdi-arrow-top-right"></i>
                                            </a>
                                        </h6>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            )}

        </React.Fragment>
    )
}

Account.propTypes = {
    addressData: PropTypes.object,
    foundationPeriod: PropTypes.object,
    overviewPeriod: PropTypes.object
}

export default Account;
