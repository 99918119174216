import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from 'prop-types'


const GovernorsVotingPerGroup = (props) => {
  const [series, setSeries] = useState([{
          name: 'Voted A',
          data: []
        },
        {
          name: 'Voted B',
          data: []
        },
        {
          name: 'Not Voted',
          data: []
        }]);

  const [options, setOptions] = useState({
    chart: {
        type: 'bar',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "40%"
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        shared: true,
        intersect: false,
        marker: { show: !1 },
        theme: false,
      },
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      xaxis: {
        title: { text: "Voted governors per group" },
        categories: ["0-1", "1-10", "10-100", "100-500", "500-1k", "1k-5k", "5k-10k", "10k-100k", "100k-1m", "1m-10m", "10m-50m", "50m-∞"],
        position: 'bot',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        }
      },
      colors: ["#556ee6", "#34c38f", "#f1b44c"],
      yaxis: {
        title: { text: "Governors" },
        labels: {
          formatter: function(value) {
            var val = Math.abs(value)
            if (val >= 1000000000) {
              val = (val / 1000000000).toFixed(0) + ' Bn'
            }
            else if (val >= 1000000) {
              val = (val / 1000000).toFixed(0) + ' M'
            }
            else if (val >= 1000) {
              val = (val / 1000).toFixed(0) + ' K'
            }
            else {
              val = (val).toFixed(0)
            }
            return val
          }
        }
      }
  });

  const getModifiedOptions = (oldOptions, categories) => {
    const modifiedOptions = {
      ...oldOptions,
      xaxis: {
        ...oldOptions.xaxis,
        categories: categories
      },
    };
    return modifiedOptions;
  }

  useEffect(() => {
    if (!props.votingPeriod.loading) {
      let votingInfo = props.votingPeriod.data.voting[props.votingIndex]["topics"][props.topicIndex]
      if (votingInfo) {
        setOptions(getModifiedOptions(options, votingInfo["voting_per_group_names"]))
      }
      setSeries([{
              name: 'Voted A',
              data: votingInfo ? votingInfo["voting_per_group_governors_a"] : new Array()
            },
            {
              name: 'Voted B',
              data: votingInfo ? votingInfo["voting_per_group_governors_b"] : new Array()
            },
            {
              name: 'Not Voted',
              data: votingInfo ? votingInfo["voting_per_group_governors_none"] : new Array()
            }]);
    }
  }, [props.votingPeriod.loading])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <p className="text-muted mb-4">
            <i
              className="mdi mdi-account-multiple h2 text-info align-middle mb-0 me-3"
            />{" "}
            Voted Governors Per Group
          </p>
          <div>
            <div id="overview-chart" className="apex-charts" dir="ltr">

                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height="350"
                />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
    );
};

GovernorsVotingPerGroup.propTypes = {
  votingPeriod : PropTypes.object,
  votingIndex: PropTypes.number,
  topicIndex: PropTypes.number,
}

export default GovernorsVotingPerGroup;
