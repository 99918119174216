import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import useFetchData from '../../data/governance/useFetchData.js'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown';
import {
  UncontrolledAlert,
  Container,
  Row, Col,
  Card, CardBody,
  Nav, NavItem, NavLink,
  Badge,
  Tooltip, DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from "reactstrap"

import {ReactComponent as AlgorandSmall} from "../../assets/images/algorand_logo_mark_black.svg"

import PeriodTimeline from "./PeriodTimeline/PeriodTimeline"
import CommittedStakePerDate from "./Graphs/CommittedStakePerDate"
import GovernorsPerGroup from "./Graphs/GovernorsPerGroup"
import CommitedStakePerGroup from "./Graphs/CommitedStakePerGroup"
import EligibleGovernors from "./EligibleGovernors"

import APYOverview from "./Overview/APYOverview"
import PeriodProcentOverview from "./Overview/PeriodProcentOverview"
import CommittedAlgosOverview from "./Overview/CommittedAlgosOverview"
import GovernorsOverview from "./Overview/GovernorsOverview"

import Voting from "./Voting/Voting"
import Account from "./Account/Account";
import {useHistory} from "react-router-dom";

const series4 = [
  { name: "Committed ALGOs", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19] },
]
const options4 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#34c38f"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { shared: true, intersect: false, fixed: { enabled: true, position: 'topRight',  offsetY: -50,offsetX: 10 }, x: { show: !1 }, marker: { show: !1 }, theme: false },
  xaxis: {
        type: "categories",
        categories: [
          "","","","","","","","","",""
        ]
  },
}


const Completionist = () => "";

const Dashboard = (props) => {
  const remote = true;
  const foundationPeriod = useFetchData("https://governance.algorand.foundation/api/periods/" + props.period);
  const overviewPeriod = useFetchData((remote ? "https://www.tryalgorand.com/api" : "http://localhost:8080") + "/governance/" + props.period);
  const infoPopup = useFetchData((remote ? "https://www.tryalgorand.com/api" : "http://localhost:8080") + "/governance/info");

  const [addressdata, setAddressdata] = useState({s: 0});
  const [addressLoading, setAddressLoading] = useState(true);

  const [stop, setstop] = useState(false);
  const [dtop, setdtop] = useState(false);

  const [refresh, setRefresh] = useState(true);

  const [governanceSelection, setGovernanceSelection] = useState(false);

  function getPeriod(period)
  {
    let p = 1;
    if (period === "governance-period-2")
    {
      p = 2;
    }
    else if (period === "governance-period-3") {
      p = 3;
    }
    else if (period === "governance-period-4") {
      p = 4;
    }
    else if (period === "governance-period-5") {
      p = 5;
    }
    else if (period === "governance-period-6") {
      p = 6;
    }
    return p;
  }

  useEffect(() => {
    const fetchData = async () => {
      setAddressLoading(true);
      try {
        const response = await fetch((remote ? "https://www.tryalgorand.com/api" : "http://localhost:8080") + "/governance/" + props.period + "/" + props.addressState["address"]);
        const jsonData = await response.json();
        jsonData["address"] = props.addressState["address"];
        setAddressdata(jsonData);
      } catch (error) {
        console.error(error)
        return {};
      }
      setAddressLoading(false);
    };
    if(props.addressState.hasOwnProperty('address')){
      fetchData();
    }
    else
    {
      setAddressdata({remote: 0})
    }

  }, [props.addressState]);

  useEffect(() => {
    if (addressdata.remote === 0) return;
    if (props.pagePosition === "account") {
      toggleTab("0");
    }

  }, [addressdata]);

  const [activeTab, setActiveTab] = useState("1")
  const [votingItems, setVotingItems] = useState([]);

  const [status, setStatus] = useState({is_active : false, is_ending : false, ended : false, is_registration : false});

  const history = useHistory();

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab === "0" && props.addressState["address"] !== undefined) {
          history.push('/' + props.period + '/account/' + props.addressState["address_name"]);
      }

      if (tab === "1" && activeTab !== "1") {
        if (props.addressState["address"] !== undefined) {
          history.push('/' + props.period + '/' + props.addressState["address_name"]);
        }
        else {
          history.push('/' + props.period);
        }
      }
      setActiveTab(tab)
    }
  }

  const getIconPopupClassName = index => {
    if (index === 1) {
      return "mdi mdi-grease-pencil me-2"
    }
    else if (index === 2) {
      return "mdi mdi-check-all me-2"
    }
    else if (index === 3) {
      return "mdi mdi-block-helper me-2"
    }
    else if (index === 4) {
      return "mdi mdi-alert-outline me-2"
    }
    else if (index === 5) {
      return "mdi mdi-alert-circle-outline me-2"
    }
    return "mdi mdi-bullseye-arrow me-2"
  }

  const getColorPopupClassName = index => {
    if (index === 1) {
      return "secondary"
    }
    else if (index === 2) {
      return "success"
    }
    else if (index === 3) {
      return "danger"
    }
    else if (index === 4) {
      return "warning"
    }
    else if (index === 5) {
      return "info"
    }
    return "primary"
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
    }
  };

  useEffect(() => {
         if (!foundationPeriod.loading)
         {
           let newVotingItems = [];
           let voting_sessions = foundationPeriod.data.voting_sessions;
           for (let i = 0; i < voting_sessions.length; ++i) {
             newVotingItems.push(voting_sessions[i]);
           }
           setVotingItems(newVotingItems);

           const todayTime = new Date().getTime();
           let is_active = foundationPeriod.data.is_active;
           let is_ended = !is_active && new Date(foundationPeriod.data.end_datetime).getTime() <= todayTime;
           let is_ending = !is_active && !is_ended && new Date(foundationPeriod.data.active_state_end_datetime).getTime() <= new Date().getTime();
           let is_registration = is_active && new Date(foundationPeriod.data.registration_end_datetime).getTime() > todayTime;

           setStatus({is_active : is_active, is_ending : is_ending, ended : is_ended, is_registration : is_registration});
         }
     }, [foundationPeriod.loading]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Algorandstats.com</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box d-block align-items-center justify-content-between">

            <div className="page-title-right">

                {!infoPopup.loading && (
                  <>
                    {infoPopup.data.info.map((item, i) => (
                      <div key={i*321} className={props.period.replace(/\D/g, "") == item.period || item.period == 0 ? "" : "collapse"}>
                        <UncontrolledAlert
                          color={getColorPopupClassName(item.type)}
                          className="alert-dismissible fade show"
                          role="alert"
                        >
                            <i className={getIconPopupClassName(item.type)}/>{item.info}
                        </UncontrolledAlert>
                      </div>
                    ))}
                  </>
                )}
               {!foundationPeriod.loading && (
                   <>
                  <Nav className={addressdata.remote === 1 ? "icon-tab nav-justified d-block d-lg-none" : "collapse"}>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "0" ? "active" : ""}
                        onClick={() => {
                          toggleTab("0")
                        }}
                      >
                        Address &nbsp;
                        <Badge className={addressdata["registration_datetime"] === "" || addressdata["is_eligible"] === 0 ? "align-top font-size-10 bg-danger" : "align-top font-size-10 bg-success"}>
                          <div >
                            {addressdata["registration_datetime"] === "" ? "Not registered" : (addressdata["is_eligible"] === 0 ? "Ineligible" : "Eligible")}
                          </div>
                        </Badge>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Nav className="icon-tab nav-justified">
                    <NavItem className={addressdata.remote === 1 ? "d-none d-lg-block" : "collapse"}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "0" ? "active" : ""}
                        onClick={() => {
                          toggleTab("0")
                        }}
                      >
                        Address &nbsp;
                        <Badge className={addressdata["registration_datetime"] === "" || addressdata["is_eligible"] === 0 ? "align-top font-size-10 bg-danger" : "align-top font-size-10 bg-success"}>
                          <div >
                            {addressdata["registration_datetime"] === "" ? "Not registered" : (addressdata["is_eligible"] === 0 ? "Ineligible" : "Eligible")}
                          </div>
                        </Badge>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggleTab("1")
                          setRefresh(!refresh)
                        }}
                      >
                        Stats &nbsp;
                        {!foundationPeriod.loading && (
                          <Badge className={status.is_active ? "align-top font-size-10 bg-success" : (status.is_ending ? "align-top font-size-10 bg-warning" : "d-none")}>
                            <div className="d-none d-lg-block">
                              {status.is_active ? "Active" : (status.is_ending ? "End soon" : "")}
                            </div>
                            <div className="d-block d-lg-none">
                              {status.is_active ? "A" : (status.is_ending ? "A" : "")}
                            </div>
                          </Badge>
                        )}
                      </NavLink>
                    </NavItem>
                      {votingItems.map((item, i) => (
                        <NavItem key={i}>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={activeTab === (i+2) ? "active" : ""}
                            onClick={() => {
                              toggleTab((i+2))
                              setRefresh(!refresh)
                            }}
                          >
                            {"Voting #" + (i+1)}&nbsp;
                            <Badge className={new Date(item.voting_start_datetime).getTime() < new Date().getTime() && new Date(item.voting_end_datetime).getTime() >= new Date().getTime()
                               ? "align-top font-size-10 bg-success" : (new Date(item.voting_end_datetime).getTime() < new Date().getTime() && new Date(item.cooldown_end_datetime).getTime() >= new Date().getTime()
                                ? "align-top font-size-10 bg-warning" : "d-none")}>
                              <div className="d-none d-lg-block">
                                {new Date(item.voting_start_datetime).getTime() < new Date().getTime() && new Date(item.voting_end_datetime).getTime() >= new Date().getTime() ? "Active" :
                                (new Date(item.voting_end_datetime).getTime() < new Date().getTime() && new Date(item.cooldown_end_datetime).getTime() >= new Date().getTime() ? "Cooldown" : "")}
                              </div>
                              <div className="d-block d-lg-none">
                                {new Date(item.voting_start_datetime).getTime() < new Date().getTime() && new Date(item.voting_end_datetime).getTime() >= new Date().getTime() ? "A" :
                                (new Date(item.voting_end_datetime).getTime() < new Date().getTime() && new Date(item.cooldown_end_datetime).getTime() >= new Date().getTime() ? "C" : "")}
                              </div>
                            </Badge>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                   </>
                 )}
            </div>
            <br/>
            <div className={activeTab === "0" ? "" : "collapse"}>
              <Account addressData={addressdata} foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
            </div>
            <div className={activeTab === "1" ? "" : "collapse"}>
              <Row>
                <Col xl="5">
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <p className="text-muted mb-4">
                            <i
                              className="mdi mdi-bell h2 text-primary align-middle mb-0 me-3"
                            />{" "}
                            Status
                          </p>

                          <Row>
                            <Col xs="12">
                              {foundationPeriod.loading && <span className="placeholder col-1"></span>}
                              {!foundationPeriod.loading && (
                                <div className="text-center m-2">
                                  <h5 ><span className={status.is_registration ? "text-info" : (status.is_active ? "text-success" : (status.is_ending ? "text-warning" : "text-primary"))}>{status.is_registration ? "Registration" : (status.is_active ? "Active" : (status.is_ending ? "End soon" : (status.ended ? "Completed" : "Start in")))}</span></h5>
                                  <p className="text-muted text-truncate mb-0">
                                    <Countdown date={new Date(!status.is_active && !status.is_ending && !status.ended ? foundationPeriod.data.start_datetime : (status.is_registration ? foundationPeriod.data.registration_end_datetime : foundationPeriod.data.active_state_end_datetime))} renderer={renderer} />
                                  </p>
                                </div>
                              )}

                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <p className="text-muted mb-4">
                            <i
                              className="mdi mdi-cards-heart h2 text-success align-middle mb-0 me-3"
                            />{" "}
                            Algo Rewards
                          </p>

                          <Row>
                            <Col xs="12">
                              <div className="text-center m-2">
                                {foundationPeriod.loading && <p className="card-text placeholder-glow"><span className="placeholder col-12"></span></p>}
                                {!foundationPeriod.loading && (
                                  <h5>{ new Intl.NumberFormat('en-US').format(foundationPeriod.data.algo_amount_in_reward_pool / 1000000) }
                                    <AlgorandSmall className="icon-style"/>
                                  </h5>
                                )}
                                <div>
                                  {!foundationPeriod.loading && (
                                    <span>{ new Intl.NumberFormat('en-US').format(foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / 1000000) }
                                      <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                                    </span>
                                  )}
                                  <Tooltip
                                        placement="top"
                                        isOpen={stop}
                                        target="sTooltipTop"
                                        toggle={() => {
                                          setstop(!stop);
                                        }}
                                        >
                                        All governance wallets (General and DeFi) will share {new Intl.NumberFormat('en-US').format(foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / 1000000)} ALGO rewards, distributed proportionally to their ALGO commitment.
                                  </Tooltip>
                                  Reward <i className="fas fa-info-circle" id="sTooltipTop"/>
                                </div>
                                <div>
                                  {!foundationPeriod.loading && (
                                      <span >{ new Intl.NumberFormat('en-US').format(foundationPeriod.data.algo_rewards_reserved_for_defi_participants / 1000000) }
                                        <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                                      </span>

                                    )}
                                    <Tooltip
                                          placement="top"
                                          isOpen={dtop}
                                          target="dTooltipTop"
                                          toggle={() => {
                                            setdtop(!dtop);
                                          }}
                                          >
                                          The DeFi bonus is in addition to the standard rewards. The { new Intl.NumberFormat('en-US').format(foundationPeriod.data.algo_rewards_reserved_for_defi_participants / 1000000) } ALGOs are distributed only among the governance wallets on the DeFi lists, proportional to their DeFi ALGO commitment.
                                    </Tooltip>
                                    DeFi bonus reward <i className="fas fa-info-circle" id="dTooltipTop"/>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col xl="7">
                  <Row>
                    <Col xl="12">
                      <APYOverview foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
                    </Col>
                    <Col xl="12">
                      <PeriodProcentOverview foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
                    </Col>
                  </Row>
                </Col>
                <Col xl="7">
                  <Row>
                    <Col xl="12">
                      <CommittedAlgosOverview foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
                    </Col>
                    <Col xl="12">
                      <GovernorsOverview foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
                    </Col>
                  </Row>
                </Col>
                <Col xl="5">
                  <PeriodTimeline foundationPeriod={foundationPeriod} />
                </Col>
              </Row>
              <Row className="h-100">
                  <Col xl="12">
                      <CommittedStakePerDate foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} refresh={refresh} />
                  </Col>
                  <Col xl="12">
                      <EligibleGovernors foundationPeriod={foundationPeriod} period={props.period} remote={remote}/>
                  </Col>

              </Row>
              <Row >
                  <Col xl="6">
                    <GovernorsPerGroup foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
                  </Col>
                  <Col xl="6">
                    <CommitedStakePerGroup foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} />
                  </Col>
              </Row>
              </div>

            {votingItems.map((item, i) => (
                <div key={i} className={activeTab == (i+2) ? "" : "collapse"}>
                <Row >
                  <Col lg="12">
                    <Voting votingIndex={i} votePeriod={item} foundationPeriod={foundationPeriod} overviewPeriod={overviewPeriod} period={props.period} refresh={refresh}/>
                  </Col>
                </Row>
                </div>
            ))}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  period: PropTypes.string,
  beta: PropTypes.bool,
  addressState: PropTypes.object,
  pagePosition: PropTypes.string
}

export default Dashboard;
