import PropTypes from 'prop-types'
import React from "react"
import CacheBuster from './CacheBuster';

import { Switch, BrowserRouter as Router, useParams } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import Layout from "./components/Layout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  return (
    <CacheBuster>
      { ({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
              <React.Fragment >
                <Router
                  basename={"/"}
                >
                  <Switch>
                    {publicRoutes.map((route, idx) => (
                      <Authmiddleware
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={idx}
                        isAuthProtected={false}
                        exact
                      />
                    ))}

                    {authProtectedRoutes.map((route, idx) => (
                      <Authmiddleware
                        path={route.path}
                        layout={Layout}
                        pagePosition={route.pagePosition}
                        component={route.component}
                        key={idx}
                        isAuthProtected={false}
                        exact
                      />
                    ))}
                  </Switch>
                </Router>
              </React.Fragment>
            );
          }}
    </CacheBuster>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
