
export function formatDate(date) {
    let dd = date.getDate();
    let mm = date.getMonth()+1;
    let yyyy = date.getFullYear();
    if(dd<10) {dd='0'+dd}
    if(mm<10) {mm='0'+mm}
    date = yyyy+'-'+mm+'-'+dd;
    return date
 }

function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export function formatDateLongWithoutYear(date) {
    let monthShort = date.toLocaleString('en-us', { month: 'short' });
    let dd = date.getDate();
    date = monthShort + ' ' + dd;
    return date
}

export function formatDateLong(date) {
    let monthShort = date.toLocaleString('en-us', { month: 'short' });
    let dd = date.getDate();
    let yyyy = date.getFullYear();
    date = monthShort + ' ' + dd + ', ' + yyyy;
    return date
}

export function formatDateWithTimeLong(date) {
    let monthShort = date.toLocaleString('en-us', { month: 'short' });
    let dd = date.getDate();
    let yyyy = date.getFullYear();
    date = monthShort + ' ' + dd + ', ' + yyyy + ' - ' + formatAMPM(date);
    return date
}

 export function formatDateWithTime(date) {
     let hh = date.getHours();
     let min = date.getMinutes();
     let dd = date.getDate();
     let mm = date.getMonth()+1;
     let yyyy = date.getFullYear();
     if(hh<10) {hh='0'+hh}
     if(min<10) {min='0'+min}
     if(dd<10) {dd='0'+dd}
     if(mm<10) {mm='0'+mm}
     date = yyyy+'-'+mm+'-'+dd + ' ' + hh + ':' + min;
     return date
  }

 export function formatDatemdyy(date) {
     var dd = date.getDate();
     var mm = date.getMonth()+1;
     var yyyy = date.getFullYear();
     if(dd<10) {dd='0'+dd}
     if(mm<10) {mm='0'+mm}
     date = mm+'/'+dd+'/'+yyyy;
     return date
  }

 export function formatDateMD(date) {
     let dd = date.getDate();
     let mm = date.getMonth()+1;
     let yyyy = date.getFullYear();
     if(dd<10) {dd='0'+dd}
     if(mm<10) {mm='0'+mm}
     date = mm+'-'+dd;
     return date
  }

export function formatNumber() {
  return "0";
}
