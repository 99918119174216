import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Row, Tooltip} from "reactstrap"
import PropTypes from 'prop-types'
import {ReactComponent as AlgorandSmall} from "../../../assets/images/algorand_logo_mark_black.svg"

const AlgosReward = (props) => {
    const [tooltipGeneral, setTooltipGeneral] = useState(false);
    const [tooltipDeFi, setTooltipDeFi] = useState(false);

    const [rewards, setRewards] = useState(0);
        const [generalRewards, setGeneralRewards] = useState(0);
    const [defiRewards, setDefiRewards] = useState(0);

    useEffect(() => {
        let p_generalRewards = props.foundationPeriod.data.total_committed_stake > 0 ? (props.addressData["committed_algo_amount"] + props.addressData["committed_assets_amount_in_algo"]) * (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / props.foundationPeriod.data.total_committed_stake)  : 0;
        let p_defiRewards = props.foundationPeriod.data.total_committed_stake_for_extra_rewards > 0 ? props.addressData["committed_algo_amount_for_extra_rewards"] * (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / props.foundationPeriod.data.total_committed_stake_for_extra_rewards) : 0;

        setGeneralRewards(p_generalRewards)
        setDefiRewards(p_defiRewards)
        setRewards(p_generalRewards + p_defiRewards - 4000)

    }, [props.foundationPeriod.loading])

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <p className="text-muted mb-4">
                        <i
                            className="mdi mdi-gift h2 text-success align-middle mb-0 me-3"
                        />{" "}
                        Period ALGOs reward
                    </p>
                    <Row>
                        <Col xs="12">
                            <div className="text-center m-2">
                                {props.foundationPeriod.loading &&
                                    <p className="card-text placeholder-glow"><span
                                        className="placeholder col-12"></span></p>}
                                {!props.foundationPeriod.loading && (
                                    <h5>{props.addressData === undefined || props.addressData["registration_datetime"] === "" || props.addressData["is_eligible"] === 0 ? "0"  : new Intl.NumberFormat('en-US', {maximumFractionDigits: 6}).format(rewards / 1000000)}
                                        <AlgorandSmall className="icon-style"/>
                                    </h5>
                                )}
                                <div>
                                    {!props.foundationPeriod.loading && (
                                        <span>{props.addressData === undefined || props.addressData["registration_datetime"] === "" || props.addressData["is_eligible"] === 0 ? "0" : new Intl.NumberFormat('en-US', {maximumFractionDigits: 6}).format(generalRewards / 1000000)}
                                        <AlgorandSmall className="icon-style" style={{height: 24}}/>
                                    </span>
                                    )}
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipGeneral}
                                        target="targetTooltipGeneral"
                                        toggle={() => {
                                            setTooltipGeneral(!tooltipGeneral);
                                        }}
                                    >
                                        All governance wallets (General and DeFi) will
                                        share {new Intl.NumberFormat('en-US').format(props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / 1000000)} ALGO
                                        rewards, distributed proportionally to their ALGO commitment.
                                    </Tooltip>
                                    Reward <i className="fas fa-info-circle" id="targetTooltipGeneral"/>
                                </div>
                                <div>
                                    {!props.foundationPeriod.loading && (
                                        <span >{props.addressData === undefined || props.addressData["registration_datetime"] === "" || props.addressData["is_eligible"] === 0 ? "0" : new Intl.NumberFormat('en-US', {maximumFractionDigits: 6}).format(defiRewards / 1000000)}
                                            <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                                      </span>

                                    )}
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipDeFi}
                                        target="targetTooltipDeFi"
                                        toggle={() => {
                                            setTooltipDeFi(!tooltipDeFi);
                                        }}
                                    >
                                        The DeFi bonus is in addition to the standard rewards. The { new Intl.NumberFormat('en-US').format(props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / 1000000) } ALGOs are distributed only among the governance wallets on the DeFi lists, proportional to their DeFi ALGO commitment.
                                    </Tooltip>
                                    DeFi bonus reward <i className="fas fa-info-circle" id="targetTooltipDeFi"/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

AlgosReward.propTypes = {
  addressData: PropTypes.object,
  foundationPeriod: PropTypes.object
}

export default AlgosReward;
