import React, {useState, useEffect } from "react"
import PropTypes from 'prop-types'
import Countdown from 'react-countdown';
import useFetchData from '../../../data/governance/useFetchData.js'
import {
  Card,
  CardBody,
  Col,
  Row,
  Nav, NavItem, NavLink,
} from "reactstrap"

import VotingTopic from "./VotingTopic"
import { formatDateWithTime } from '../../../helpers/formatter.js'

const Voting = (props) => {
  const remote = true;

  const foundationVoting = useFetchData("https://governance.algorand.foundation/api/voting-sessions/"+ props.votePeriod.slug +"/");
  const votingPeriod = useFetchData((remote ? "https://www.tryalgorand.com/api" : "http://localhost:8080") + "/governance/" + props.period + "/voting/");

  const [refresh, setRefresh] = useState(true);

  const [topicItems, setTopicItems] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [votingData, setVotingData] = useState({is_active: false});



  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const Completionist = () => "";

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      // Render a countdown
      return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
    }
  };

  useEffect(() => {
    setRefresh(!refresh)
  }, [props.refresh]);

  useEffect(() => {
         if (!foundationVoting.loading && !votingPeriod.loading)
         {
           setTopicItems(foundationVoting.data.topics);

           let total_voted_governors = 0;
           let total_voted_algos = 0;
           if (foundationVoting.data.topics.length == votingPeriod.data.voting[props.votingIndex].topics.length &&
              foundationVoting.data.topics.length > 0 &&
              foundationVoting.data.topics[0]["id"] == votingPeriod.data.voting[props.votingIndex].topics[0]["topic_id"])
           {
             for (let i = 0; i < foundationVoting.data.topics.length; i++) {
               let total_voted_a_governors = votingPeriod.data.voting[props.votingIndex].topics[i]["total_voted_a_governors"] ?? 0;
               let total_voted_b_governors = votingPeriod.data.voting[props.votingIndex].topics[i]["total_voted_b_governors"] ?? 0;
               let total_voted_a_algos  = votingPeriod.data.voting[props.votingIndex].topics[i]["total_voted_a_algos"] ?? 0;
               let total_voted_b_algos  = votingPeriod.data.voting[props.votingIndex].topics[i]["total_voted_b_algos"] ?? 0;

               total_voted_governors = total_voted_a_governors + total_voted_b_governors;
               total_voted_algos = (total_voted_a_algos + total_voted_b_algos) / 1000000;
             }
           }

           let is_active = new Date(foundationVoting.data.cooldown_end_datetime).getTime() >= new Date().getTime() && new Date(foundationVoting.data.voting_start_datetime).getTime() <= new Date().getTime();
           setVotingData({is_active: is_active, total_voted_governors: total_voted_governors, total_voted_algos: total_voted_algos});
         }
     }, [foundationVoting.loading, votingPeriod.loading, props.refresh]);

  return (
    <React.Fragment>
      <Row>
        <Col xl="7" md="12">
          <Card>
            <CardBody>
              <p className="text-muted mb-4">
                <i
                  className="mdi mdi-vote h2 text-success align-middle mb-0 me-3"
                />{" "}
                {!foundationVoting.loading && (
                  <>
                  {foundationVoting.data.title}
                  </>
                )}
              </p>
              <Row className="text-left">
                <div className="mt-0">
                {!foundationVoting.loading && (
                  <>
                  <div dangerouslySetInnerHTML={{__html: foundationVoting.data.description_html}} />
                  </>
                )}
                </div>
              </Row>
              <Row className="task-dates">
                <Col sm="4" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-13">
                      <i className="bx bx-calendar me-1 text-primary" /> Voting start
                    </h5>
                    <p className="text-muted mb-0">
                      {!foundationVoting.loading && (
                        <>
                        {formatDateWithTime(new Date(foundationVoting.data.voting_start_datetime))}
                        </>
                      )}
                    </p>
                  </div>
                </Col>

                <Col sm="4" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-13">
                      <i className="bx bx-calendar-check me-1 text-primary" /> Voting end
                    </h5>
                    <p className="text-muted mb-0">
                      {!foundationVoting.loading && (
                        <>
                        {formatDateWithTime(new Date(foundationVoting.data.voting_end_datetime))}
                        </>
                      )}
                    </p>
                  </div>
                </Col>
                <Col sm="4" xs="6">
                  <div className="mt-4">
                    <h5 className="font-size-13">
                      <i className="bx bx-calendar-check me-1 text-primary" /> Cooldown end
                    </h5>
                    <p className="text-muted mb-0">
                    {!foundationVoting.loading && (
                      <>
                      {formatDateWithTime(new Date(foundationVoting.data.cooldown_end_datetime))}
                      </>
                    )}
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xl="3">
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <p className="text-muted mb-4">
                    <i
                      className="mdi mdi-cash h2 text-info align-middle mb-0 me-3"
                    />{" "}
                    Total Voted Algos
                  </p>

                  <Row>
                    <Col xs="12">
                      {votingPeriod.loading && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
                      <h5>{!votingPeriod.loading  && (
                        <>
                        {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(votingData.total_voted_algos)}
                        </>
                      )}</h5>
                    </Col>
                  </Row>
                  <div className="hidden">
                  -
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
              <Card>
                <CardBody>
                  <p className="text-muted mb-4">
                    <i
                      className="mdi mdi-account-multiple h2 text-warning align-middle mb-0 me-3"
                    />{" "}
                    Total Voted Governors
                  </p>
                  {votingPeriod.loading && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
                  <h5>{!votingPeriod.loading && (
                    <>
                    {votingData.total_voted_governors}
                    </>

                  )}</h5>
                  <Row>
                    <Col xs="12">
                    <div className="hidden">
                    -
                    </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="2">
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <p className="text-muted mb-4">
                    <i
                      className="mdi mdi-bell h2 text-primary align-middle mb-0 me-3"
                    />{" "}
                    Status
                  </p>

                  <Row>
                    <Col xs="12">
                      {foundationVoting.loading && <span className="placeholder col-1"></span>}
                      {!foundationVoting.loading  && (
                        <div>
                          <h5><span className={votingData.is_active ? "text-success" : "text-primary"}>{votingData.is_active ? (new Date(foundationVoting.data.voting_end_datetime).getTime() < new Date().getTime() ? "Cooldown"  : "Active") : (new Date(foundationVoting.data.cooldown_end_datetime).getTime() > new Date().getTime() ? "Start in"  : "Completed")}</span></h5>
                          <p className="text-muted text-truncate mb-0">
                            <Countdown date={new Date(!votingData.is_active && new Date(foundationVoting.data.cooldown_end_datetime).getTime() > new Date().getTime() ? foundationVoting.data.voting_start_datetime : (new Date(foundationVoting.data.voting_end_datetime).getTime() > new Date().getTime() ? foundationVoting.data.voting_end_datetime : foundationVoting.data.cooldown_end_datetime))} renderer={renderer} />
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="12">
              <Card>
                <CardBody>
                  <p className="text-muted mb-4">
                    <i
                      className="mdi mdi-circle-multiple h2 text-info align-middle mb-0 me-3"
                    />{" "}
                    Measures
                  </p>

                  <Row>
                    <Col xs="12">
                      {foundationVoting.loading && <span className="placeholder col-1"></span>}
                      {!foundationVoting.loading  && (
                        <div>
                          <h5>{foundationVoting.data.topic_count}</h5>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="hidden">
                  -
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Nav className="icon-tab nav-justified">
        {topicItems.map((item, i) => (
          <NavItem key={i}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={activeTab === (i+1) ? "active" : ""}
              onClick={() => {
                toggleTab((i+1))
                setRefresh(!refresh)
              }}
            >
            {"Measure #" + (i+1)}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <br></br>

      {topicItems.map((item, i) => (
        <div key={100+i} className={activeTab == (i+1) ? "" : "collapse1"}>

                <VotingTopic votingIndex={props.votingIndex} topicIndex={i} TopicPeriod={item} votingPeriod={votingPeriod} foundationVoting={foundationVoting} refresh={refresh}
                  overviewPeriod={props.overviewPeriod} foundationPeriod={props.foundationPeriod}/>

        </div>
      ))}
    </React.Fragment>
  )
}

Voting.propTypes = {
  votePeriod : PropTypes.object,
  foundationPeriod: PropTypes.object,
  overviewPeriod: PropTypes.object,
  period: PropTypes.string,
  refresh: PropTypes.bool,
  votingIndex: PropTypes.number
}

export default Voting;
