import React,{useState, useEffect } from "react"
import PropTypes from 'prop-types'

import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"

import VotesPerDay from "../Graphs/VotesPerDay"
import CommitedStakeVotingPerGroup from "../Graphs/CommitedStakeVotingPerGroup"
import GovernorsVotingPerGroup from "../Graphs/GovernorsVotingPerGroup"

const VotingTopic = (props) => {

  const [topic, setTopic] = useState({"aprocent" : 0, "bprocent" : 0, "adis_governors" : 0, "bdis_governors" : 0, "adis_governors_procent" : 0, "bdis_governors_procent" : 0, "nonedis_governors": 0,
      "adis_algo":0, "bdis_algo": 0, "nonedis_algo": 0, "adis_algo_procent": 0, "bdis_algo_procent": 0, "nonedis_algo_procent":0});

  const [aTitle, setATitle] = useState("")
  const [bTitle, setBTitle] = useState("")

  useEffect(() => {
    if (!props.TopicPeriod.loading)
    {
      setATitle(props.TopicPeriod["topic_options"][0]["indicator"].toUpperCase())
      setBTitle(props.TopicPeriod["topic_options"][1]["indicator"].toUpperCase())
    }
  }, [props.TopicPeriod]);

  useEffect(() => {
    if (!props.votingPeriod.loading)
    {
      for (let i = 0; i < props.votingPeriod.data.voting.length; ++i) {
        for (let j = 0; j < props.votingPeriod.data.voting[i].topics.length; ++j) {
          if (props.votingPeriod.data.voting[i].topics[j]["topic_id"] == props.TopicPeriod.id){

            let total = props.votingPeriod.data.voting[i].topics[j]["total_voted_a_algos"] + props.votingPeriod.data.voting[i].topics[j]["total_voted_b_algos"];
            let totalGovernor = props.votingPeriod.data.voting[i].topics[j]["total_voted_a_governors"] + props.votingPeriod.data.voting[i].topics[j]["total_voted_b_governors"] + props.votingPeriod.data.voting[i].topics[j]["total_voted_none_governors"];
            let totalalgo = props.votingPeriod.data.voting[i].topics[j]["total_voted_a_algos"] + props.votingPeriod.data.voting[i].topics[j]["total_voted_b_algos"] + props.votingPeriod.data.voting[i].topics[j]["total_voted_none_algos"];

            let top =  {
              ...topic,
              "aprocent": total == null || total == 0 ? 0 : props.votingPeriod.data.voting[i].topics[j]["total_voted_a_algos"] * 100 / total,
              "bprocent": total == null || total == 0 ? 0 : props.votingPeriod.data.voting[i].topics[j]["total_voted_b_algos"] * 100 / total,
              "adis_governors": props.votingPeriod.data.voting[i].topics[j]["total_voted_a_governors"],
              "bdis_governors": props.votingPeriod.data.voting[i].topics[j]["total_voted_b_governors"],
              "nonedis_governors": props.votingPeriod.data.voting[i].topics[j]["total_voted_none_governors"],
              "adis_governors_procent": totalGovernor == null || totalGovernor == 0 ? 0 : props.votingPeriod.data.voting[i].topics[j]["total_voted_a_governors"] * 100 / totalGovernor,
              "bdis_governors_procent": totalGovernor == null || totalGovernor == 0 ? 0 :props.votingPeriod.data.voting[i].topics[j]["total_voted_b_governors"]* 100 / totalGovernor,
              "nonedis_governors_procent": totalGovernor == null || totalGovernor == 0 || props.votingPeriod.data.voting[i].topics[j]["total_voted_none_governors"] == 0 ? 0  :props.votingPeriod.data.voting[i].topics[j]["total_voted_none_governors"]* 100 / totalGovernor,

              "adis_algo": props.votingPeriod.data.voting[i].topics[j]["total_voted_a_algos"],
              "bdis_algo": props.votingPeriod.data.voting[i].topics[j]["total_voted_b_algos"],
              "nonedis_algo": props.votingPeriod.data.voting[i].topics[j]["total_voted_none_algos"],
              "adis_algo_procent": totalalgo == 0 ? 0 : props.votingPeriod.data.voting[i].topics[j]["total_voted_a_algos"] * 100 / totalalgo,
              "bdis_algo_procent": totalalgo == 0 ? 0 : props.votingPeriod.data.voting[i].topics[j]["total_voted_b_algos"] * 100 / totalalgo,
              "nonedis_algo_procent": totalalgo == 0 ? 0 : props.votingPeriod.data.voting[i].topics[j]["total_voted_none_algos"] * 100 / totalalgo,
            }
            setTopic(top)
            //setTopic(props.votingPeriod.data.voting[i].topics[j])
            return;
          }
        }
      }
    }
  }, [props.votingPeriod]);

  return (
    <React.Fragment>
      <Row>
        <Col xl="8" md="8">
          <Card>
            <CardBody>
              <p className="text-muted mb-4">
                <i
                  className="mdi mdi-alphabet-latin h2 text-primary align-middle mb-0 me-3"
                />{" "}
                {props.TopicPeriod.title}
              </p>
              <h6>Results</h6>
              <div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {aTitle} <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["aprocent"]) + "%"}</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: topic["aprocent"] + "%" }}
                          aria-valuenow={topic["aprocent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {bTitle} <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["bprocent"]) + "%"}</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: topic["bprocent"] + "%" }}
                          aria-valuenow={topic["bprocent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <hr></hr>

              <br></br>
              <h6>Details</h6>
              <Row className="text-left">
                <div className="mt-0">
                  <div dangerouslySetInnerHTML={{__html: props.TopicPeriod.description_html}} />
                </div>
              </Row>



            </CardBody>
          </Card>
        </Col>
        <Col xl="4" md="4">
          <Card>
            <CardBody>
              <p className="text-muted mb-4">
                <i
                  className="mdi mdi-chess-king h2 text-seconds align-middle mb-0 me-3"
                />{" "}
                 Current Vote Winner
              </p>

              <Row>
                <Col xs="12">
                  <h5><span className={topic["aprocent"] == topic["bprocent"] ? "text-warning" : topic["aprocent"] > topic["bprocent"] ? "text-primary" : "text-success"}>{topic["aprocent"] == topic["bprocent"] ? "-" : topic["aprocent"] > topic["bprocent"] ? "A" : "B"}</span></h5>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <p className="text-muted mb-4">
                <i
                  className="mdi mdi-account-multiple h2 text-warning align-middle mb-0 me-3"
                />{" "}
                Vote Distribution Governors
              </p>
              <div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {aTitle} - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(topic["adis_governors"])} <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["adis_governors_procent"])}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: topic["adis_governors_procent"] + "%" }}
                          aria-valuenow={topic["adis_governors_procent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {bTitle} - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(topic["bdis_governors"])} <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["bdis_governors_procent"])}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: topic["bdis_governors_procent"] + "%" }}
                          aria-valuenow={topic["bdis_governors_procent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        Not voted - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(topic["nonedis_governors"])} <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["nonedis_governors_procent"])}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: topic["nonedis_governors_procent"] + "%" }}
                          aria-valuenow={topic["nonedis_governors_procent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <p className="text-muted mb-4">
                <i
                  className="mdi mdi-cash h2 text-info align-middle mb-0 me-3"
                />{" "}
                Vote Distribution Algos
              </p>
              <div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {aTitle} - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(topic["adis_algo"]/ 1000000)} Algos <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["adis_algo_procent"])}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: topic["adis_algo_procent"] + "%" }}
                          aria-valuenow={topic["adis_algo_procent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {bTitle} - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(topic["bdis_algo"]/ 1000000)} Algos <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["bdis_algo_procent"])}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: topic["bdis_algo_procent"] + "%" }}
                          aria-valuenow={topic["bdis_algo_procent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item">
                    <div className="py-2">
                      <h5 className="font-size-14">
                        Not voted - {new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(topic["nonedis_algo"]/ 1000000)} Algos <span className="float-end">{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(topic["nonedis_algo_procent"])}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: topic["nonedis_algo_procent"] + "%" }}
                          aria-valuenow={topic["nonedis_algo_procent"]}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl="12" md="12">
          <VotesPerDay votingIndex={props.votingIndex} topicIndex={props.topicIndex} votingPeriod={props.votingPeriod} TopicPeriod={props.TopicPeriod} foundationVoting={props.foundationVoting} refresh={props.refresh}
          overviewPeriod={props.overviewPeriod} foundationPeriod={props.foundationPeriod}/>
        </Col>
      </Row>
      <Row >
          <Col xl="6">
            <CommitedStakeVotingPerGroup votingPeriod={props.votingPeriod} votingIndex={props.votingIndex} topicIndex={props.topicIndex} />
          </Col>
          <Col xl="6">
            <GovernorsVotingPerGroup votingPeriod={props.votingPeriod} votingIndex={props.votingIndex} topicIndex={props.topicIndex} />
          </Col>
      </Row>
    </React.Fragment>
  )
}

VotingTopic.propTypes = {
  TopicPeriod : PropTypes.object,
  votingPeriod : PropTypes.object,
  foundationVoting: PropTypes.object,
  foundationPeriod: PropTypes.object,
  overviewPeriod: PropTypes.object,
  refresh: PropTypes.bool,
  votingIndex: PropTypes.number,
  topicIndex: PropTypes.number,
}

export default VotingTopic;
