import React, { useState, useEffect } from "react"
import {  Row, Col, Card, CardBody, Tooltip} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types'
import { formatDate } from '../../../helpers/formatter.js'

const PeriodProcentOverview = (props) => {
  const [series, setSeries] = useState([{ name: "Period reward %", data: [] }])
  const [today, setToday] = useState(0)
  const [total, setTotal] = useState(0)

  const [perioddefitop, setPerioddefitop] = useState(false);
  const [periodstandardtop, setPeriodstandardtop] = useState(false);

  const [defiseries, setDefiseries] = useState([{ name: "Governance DeFi APR", data: [] }])
  const [defitoday, setDefitoday] = useState(0)
  const [defitotal, setDefiTotal] = useState(0)

  const [options, setOptions] = useState({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#3452e1"],
    stacked: false,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      fixed: { enabled: true, position: 'topRight',  offsetY: -75,offsetX: 20 },
      marker: { show: !1 },
      theme: false,
    },
    xaxis: {
      type: "categories",
      categories: [
        "","","","","","","","","",""
      ]
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          let plus = value > 0 ? "+" : "";
          return plus + Number(value.toFixed(6)) + " %";
        }
      },
      reversed: false
    }
  });

  const [defioptions, setDefioptions] = useState({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#a150a0"],
    stacked: false,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      fixed: { enabled: true, position: 'topRight',  offsetY: -75,offsetX: 20 },
      marker: { show: !1 },
      theme: false,
    },
    xaxis: {
      type: "categories",
      categories: [
        "","","","","","","","","",""
      ]
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          let plus = value > 0 ? "+" : "";
          return plus + Number(value.toFixed(6)) + " %";
        }
      },
      reversed: false
    }
  });

  const getModifiedReversed = (oldOptions, reversed) => {
    const modifiedOptions = {
      ...oldOptions,
      yaxis: {
        ...oldOptions.yaxis,
        reversed: reversed
      },
    };
    return modifiedOptions;
  }

  let categories = [];
  for (var i=0; i<20; i++) {
    var date = new Date();
    date.setDate(date.getDate() - i);
    categories.push("Last 20 days - " + formatDate(date));
  }

  options["xaxis"]["categories"] = categories.reverse();
  defioptions["xaxis"]["categories"] = categories

  useEffect(() => {
         if (!props.overviewPeriod.loading && !props.foundationPeriod.loading)
         {
           const len = props.overviewPeriod.data.committed_stake.interval_per_day.length;

           const intervalPerDay = props.overviewPeriod.data.committed_stake.interval_per_day.slice(Math.min(len,20) * -1);
           let newData = []
           let total_committed_stake = props.overviewPeriod.data.committed_stake.current_count;
           let newproc = (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / props.overviewPeriod.data.committed_stake.current_count * 100);
           for (var i = intervalPerDay.length - 1; i >= 0; i--) {
             let day = intervalPerDay[i];
             total_committed_stake -= day;
             let proc = total_committed_stake > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / total_committed_stake * 100) : 0;
             newData.push(newproc - proc);
             newproc = proc;
           }

           while (newData.length < 20)
           {
             newData.push(0);
           }

           let p1 = props.overviewPeriod.data.committed_stake.current_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / props.overviewPeriod.data.committed_stake.current_count * 100) : 0;
           let p2 = props.overviewPeriod.data.committed_stake.start_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / props.overviewPeriod.data.committed_stake.start_count * 100) : 0;
           if (newData.length > 0)
           {
             setToday(Number(newData[0].toFixed(6)));
           }
           setTotal(Number((p1 - p2).toFixed(6)));
           setSeries([{ name: "Governance APY", data: newData.reverse()}]);

           let result = true;
           for (let i = 0; i < newData.length; i++) {
                if (newData[i] > 0) {
                    result = false;
                    break;
                }
            }

            //defi part
            const defi_len = props.overviewPeriod.data.defi_committed_stake.interval_per_day.length;

            const defi_intervalPerDay = props.overviewPeriod.data.defi_committed_stake.interval_per_day.slice(Math.min(defi_len,20) * -1);
            let defi_newData = []
            let defi_total_committed_stake = props.overviewPeriod.data.defi_committed_stake.current_count;
            let defi_newproc = (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / props.overviewPeriod.data.defi_committed_stake.current_count * 100);
            for (var i = defi_intervalPerDay.length - 1; i >= 0; i--) {
              let defi_day = defi_intervalPerDay[i];
              defi_total_committed_stake -= defi_day;
              let defi_proc = defi_total_committed_stake > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / defi_total_committed_stake * 100) : 0;
              defi_newData.push(defi_newproc - defi_proc);
              defi_newproc = defi_proc;
            }

            while (defi_newData.length < 20)
            {
              defi_newData.push(0);
            }

            let defi_p1 = props.overviewPeriod.data.defi_committed_stake.current_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / props.overviewPeriod.data.defi_committed_stake.current_count * 100) : 0;
            let defi_p2 = props.overviewPeriod.data.defi_committed_stake.start_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / props.overviewPeriod.data.defi_committed_stake.start_count * 100) : 0;
            if (defi_newData.length > 0)
            {
              let dtoday = Number(defi_newData[0].toFixed(6));
              if (isNaN(dtoday)) {
                dtoday = 0;
              }
              setDefitoday(dtoday);
            }
            setDefiTotal(Number((defi_p1 - defi_p2).toFixed(6)));
            setDefiseries([{ name: "Governance APY", data: defi_newData.reverse()}]);

            let defi_result = true;
            for (let i = 0; i < defi_newData.length; i++) {
                 if (defi_newData[i] > 0) {
                     defi_result = false;
                     break;
                 }
             }

           setOptions(getModifiedReversed(options, result));
           setDefioptions(getModifiedReversed(defioptions, defi_result));
         }
     }, [props.overviewPeriod.loading, props.foundationPeriod.loading])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <p className="text-muted mb-4">
            <i
              className="mdi mdi-percent h2 text-primary align-middle mb-0 me-3"
            />{" "}
            Period % Reward
          </p>

          <Row>
            <Col xs="12" sm="12" xl="6">
              <div className="text-center m-3">
                <h5 className="font-size-15"><Tooltip
                      placement="top"
                      isOpen={periodstandardtop}
                      target="periodstandardtopTooltipTop"
                      toggle={() => {
                        setPeriodstandardtop(!periodstandardtop);
                      }}
                      >
                      {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                        <>
                          All governance wallets (General and DeFi) are getting { new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(props.overviewPeriod.data.committed_stake.current_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / props.overviewPeriod.data.committed_stake.current_count * 100) : 0)  + "%" } period rewards over their ALGO commitment.
                        </>
                      )}
                </Tooltip>Governance <i className="fas fa-info-circle" id="periodstandardtopTooltipTop"/></h5>
                {props.foundationPeriod.loading && <p className="card-text placeholder-glow"><span className="placeholder col-12"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                  <h5 style={{ fontWeight: 'bold', margin:"20px" }}>{ new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(props.overviewPeriod.data.committed_stake.current_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_non_defi_participants / props.overviewPeriod.data.committed_stake.current_count * 100) : 0)  + " %" }</h5>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                  <span className={today == 0 ? "badge badge-soft-info font-size-12 me-1" : today > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (today > 0 ? "+" : "") + today + " %" }
                  </span>{" "}
                  From yesterday
                </div>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                  <span className={total == 0 ? "badge badge-soft-info font-size-12 me-1" : total > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (total > 0 ? "+" : "") + total + " %" }
                  </span>{" "}
                  From start period
                </div>
                )}
              </div>
              <div className="mx-5 my-4">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={61}
                  className="apex-charts"
                />
              </div>
            </Col>
            <Col xs="12" sm="12" xl="6">
              <div className="text-center m-3">
                <h5 className="font-size-15"><Tooltip
                      placement="top"
                      isOpen={perioddefitop}
                      target="perioddefitopTooltipTop"
                      toggle={() => {
                        setPerioddefitop(!perioddefitop);
                      }}
                      >
                      {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                        <>
                          In addition to the standard rewards, governance wallets on the DeFi lists are getting an extra { new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(props.overviewPeriod.data.defi_committed_stake.current_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / props.overviewPeriod.data.defi_committed_stake.current_count * 100) : 0)  + "%" } period rewards over their DeFi ALGO commitment.
                        </>
                      )}
                </Tooltip>DeFi Governance <i className="fas fa-info-circle" id="perioddefitopTooltipTop"/></h5>
                {props.foundationPeriod.loading && <p className="card-text placeholder-glow"><span className="placeholder col-12"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                  <h5 style={{ fontWeight: 'bold', margin:"20px" }}>{ new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(props.overviewPeriod.data.defi_committed_stake.current_count > 0 ? (props.foundationPeriod.data.algo_rewards_reserved_for_defi_participants / props.overviewPeriod.data.defi_committed_stake.current_count * 100) : 0)  + " %" }</h5>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                <span className={defitoday == 0 ? "badge badge-soft-info font-size-12 me-1" : defitoday > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                  {" "}
                  { (defitoday > 0 ? "+" : "") + defitoday + " %" }
                </span>{" "}
                From yesterday
                </div>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                <span className={defitotal == 0 ? "badge badge-soft-info font-size-12 me-1" : defitotal > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                  {" "}
                  { (defitotal > 0 ? "+" : "") + defitotal + " %" }
                </span>{" "}
                From start period
                </div>
                )}
              </div>
              <div className="mx-5 my-4">
                <ReactApexChart
                  options={defioptions}
                  series={defiseries}
                  type="bar"
                  height={61}
                  className="apex-charts"
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

    </React.Fragment>
  );
}

PeriodProcentOverview.propTypes = {
  foundationPeriod: PropTypes.object,
  overviewPeriod: PropTypes.object
}

export default PeriodProcentOverview;
