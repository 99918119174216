import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';

import { connect } from "react-redux"
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation"

import { Link, useParams, useLocation, withRouter   } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, Tooltip } from "reactstrap"

import logoLight from "../../assets/images/logo-light.png"
import logoLightSmall from "../../assets/images/logo-light-small.png"

// import images
import twitter from "../../assets/images/brands/twitter.png"
import email from "../../assets/images/brands/email.png"
import donate from "../../assets/images/brands/donate.png"

//i18n
import { withTranslation } from "react-i18next"


const Header = props => {
  const [dark, setDark] = useState(false)
  const [menu, setMenu] = useState(false)
  const [isSearch, setSearch] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const [inputHandle, setInputHandle] = useState('')
  const [basePath, setBasePath] = useState('')

  const [tDonate, setTDonate] = useState(false);
  const [tMail, setTMail] = useState(false);
  const [tTwitter, setTTwitter] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false);
  }

  const setTheme = () => {
    var darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'enabled')
    {
      document.body.classList.add('theme-darkTheme');
      if (!dark)
      {
        setDark(true);
      }
    }
    else
    {
      document.body.classList.add('theme-lightTheme');
      if (dark)
      {
        setDark(false);
      }
    }
  }

  const handleThemeSwitch = () => {
    var darkMode = localStorage.getItem('darkMode');
    if (darkMode === 'enabled')
    {
      document.body.classList.remove('theme-darkTheme');
      document.body.classList.add('theme-lightTheme');
      localStorage.setItem('darkMode', null);
      setDark(false);
    }
    else
    {
      document.body.classList.remove('theme-lightTheme');
      document.body.classList.add('theme-darkTheme');
      localStorage.setItem('darkMode', 'enabled');
      setDark(true);
    }
  };

  setTheme();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { address } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (address === undefined)
    {
      setBasePath(location.pathname);
      setInputHandle("");
    }
    else
    {
      setBasePath(location.pathname.substring(0, location.pathname.lastIndexOf('/')));
      setInputHandle(address);
    }

  },  [basePath]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://indexer.algoexplorerapi.io/v2/accounts/" + address);
        const jsonData = await response.json();

        const response1 = await fetch("https://api.nf.domains/nfd/" + address +"?view=tiny&poll=false&nocache=false");
        const jsonData1 = await response1.json();
        jsonData["address_name"] = address;
        if (jsonData["account"] != null) {
          jsonData["address"] = jsonData["account"]["address"];
        }
        if (jsonData1.hasOwnProperty("caAlgo")) {
          jsonData["address"] = jsonData1["caAlgo"][0];
          jsonData["address_name"] =  jsonData1["name"];
        }

        setData(jsonData);
      } catch (error) {
        console.error(error)
        return {};
      }
      setLoading(false);
    };
    fetchData();
  },  [address]);

  useEffect(() => {
    if (!loading)
    {
      if(data.hasOwnProperty("address")){
        props.onAddressUpdate({empty:false, valid: true, address: data["address"], address_name: data["address_name"]})
      }
      else
      {
        let empty = address === undefined;
        props.onAddressUpdate({empty:empty, valid: false})
      }
    }
  }, [loading]);

  const validate = (value, ctx, input, cb) => {
    cb(false);
  }

  const handleKeyPress = (target, value) => {
    if(target.charCode===13){
      const { history } = props;
      let base = basePath.slice(-1) === "/" ? basePath.slice(0, -1) : basePath;
      if (inputHandle === "")
      {
        history.push(`${base}`);
      }
      else
      {
        history.push(`${base}/${inputHandle}`);
      }
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <button
              type="button"
              className={props.leftMenu ? "btn d-none header-item noti-icon-small": "btn d-lg-none header-item noti-icon-small"}
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(true)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <button
                type="button"
                className={props.leftMenu ? "btn d-lg-none header-item noti-icon-small": "btn d-none header-item noti-icon-small"}
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-angle-down" />
            </button>

            <div className="navbar-brand-box">
              <span className="logo logo-light logo-lg">
                <a href="/"><img src={logoLight} alt="" height="19" /></a>
              </span>
            </div>
          </div>

          <div className="d-flex">
            <div className="app-search d-none d-lg-block">
              <div className="position-relative">
                <div className="input-group">
                  <input
                    type="text"
                    value={inputHandle}
                    onChange={ (event) => setInputHandle(event.target.value) }
                    className={loading || props.addressState.empty || props.addressState.valid ? "form-control" : "form-control-invalid"}
                    placeholder={props.t("Enter algo or .algo address") + "..."}
                    aria-label="Recipient's username"
                    onKeyPress={e => handleKeyPress(e)}
                  />
                  <div className="input-group-append">
                    <Link className="btn btn-primary" to={basePath.slice(-1) === "/" ? `${basePath.slice(0, -1)}/${inputHandle}` : `${basePath}/${inputHandle}`}>
                      <i className="mdi mdi-magnify" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbar-brand-box-small">
              <span className="logo logo-light logo-sm">
                <img src={logoLight} alt="" height="19" />
              </span>
            </div>
            <div className="navbar-brand-box-smaller">
              <span className="logo logo-light logo-sm">
                <img src={logoLightSmall} alt="" height="12" />
              </span>
            </div>
          </div>
          <div className="d-flex">
            <Dropdown
              className="dropdown d-block d-lg-none ms-2"
              isOpen={isSearch}
              toggle={() => {
                setSearch(!isSearch)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon"
                caret
                tag="button"
              >
                <i className="mdi mdi-magnify" />
              </DropdownToggle>
              <div
                  className={
                    isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <div className="p-3" >
                    <div className="form-group m-1" >
                      <div className="input-group" >
                        <input
                          name="addressq"
                          type="text"
                          className={loading || props.addressState.empty || props.addressState.valid ? "form-control" :  "form-control invalid"}
                          value={inputHandle}
                          onChange={ (event) => setInputHandle(event.target.value) }
                          placeholder={props.t("Enter algo or .algo address") + "..."}
                          aria-label="Recipient's username"
                          onKeyPress={e => handleKeyPress(e)}
                        />
                        <div className="input-group-append">
                          <Link className="btn btn-primary" to={basePath.slice(-1) === "/" ? `${basePath.slice(0, -1)}/${inputHandle}` : `${basePath}/${inputHandle}`}>
                            <i className="mdi mdi-magnify" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </Dropdown>

            <button
                id="TooltipDonate"
                type="button"
                className={"btn header-item noti-icon-small d-none d-lg-block "}
                style={{marginBottom : 1}}
              >
                <a target="_blank" rel="noreferrer" href="https://algoexplorer.io/address/44RIJQISEHJB5JQ3HBNNR7DYVEKMYJJKB4FUCS44X6GB5MKHO3TIJH3AUQ">
                  <i className={"mdi mdi-gift"} />
                </a>
            </button>

            <Dropdown
              className="d-inline-block"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp)
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon"
                caret
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-2 gx-0">
                  <Row className="gx-0">
                    <Col>
                      <Tooltip
                        placement="top"
                        isOpen={tTwitter}
                        target="TooltipTwitter"
                        toggle={() => {
                          setTTwitter(!tTwitter);
                        }}
                      >
                        Twitter - @algorandstats
                      </Tooltip>
                      <a id="TooltipTwitter" className="dropdown-icon-item" target="_blank" rel="noreferrer" href="https://twitter.com/algorandstats" title="Twitter">
                        <img src={twitter} alt="Twitter" />
                        <span>Twitter</span>
                      </a>
                    </Col>
                    <Col>
                      <Tooltip
                        placement="top"
                        isOpen={tMail}
                        target="TooltipMail"
                        toggle={() => {
                          setTMail(!tMail);
                        }}
                      >
                        Mail - info@algorandstats.com
                      </Tooltip>
                      <a id="TooltipMail" className="dropdown-icon-item"  href="mailto:info@algorandstats.com">
                        <img src={email} alt="mail_chimp" />
                        <span>Email</span>
                      </a>
                    </Col>
                    <Col>
                      <Tooltip
                        placement="top"
                        isOpen={tDonate}
                        target="TooltipDonate"
                        toggle={() => {
                          setTDonate(!tDonate);
                        }}
                      >
                        Donate - 44RIJQISEHJB5JQ3HBNNR7DYVEKMYJJKB4FUCS44X6GB5MKHO3TIJH3AUQ
                      </Tooltip>
                      <a id="TooltipDonate" className="dropdown-icon-item" target="_blank" rel="noreferrer" href="https://algoexplorer.io/address/44RIJQISEHJB5JQ3HBNNR7DYVEKMYJJKB4FUCS44X6GB5MKHO3TIJH3AUQ">
                        <img src={donate} alt="Donate" />
                        <span>Donate</span>
                      </a>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown>

            <button
                type="button"
                className={"btn header-item noti-icon-small"}
                onClick={() => {
                  handleThemeSwitch();
                }}
              >
                <i className={dark ? "fa fa-fw fa-sun" : "fa fa-fw fa-moon"} />
            </button>

          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  onAddressUpdate: PropTypes.func,
  addressState: PropTypes.object,
  history: PropTypes.object
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(withRouter(Header)))
