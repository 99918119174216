import React from "react"
import { Card, CardBody} from "reactstrap"
import PropTypes from 'prop-types'
import {formatDateWithTime, formatDateWithTimeLong} from '../../../helpers/formatter.js'


const Registered = (props) => {
    return (
        <React.Fragment>
            <Card style={{height:150}}>
                <CardBody>
                    <p className="text-muted mb-4">
                        <i
                            className="mdi mdi-calendar-text h2 text-primary align-middle mb-0 me-3"
                        />{" "}
                        Registered
                    </p>
                    <div className="text-center m-2">
                        <h5>{props.addressData === undefined || props.addressData["registration_datetime"] == "" ? "Not registered" : formatDateWithTimeLong(new Date(props.addressData["registration_datetime"]))}</h5>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

Registered.propTypes = {
    addressData: PropTypes.object,
}

export default Registered;
