import React, { useState } from "react"
import { Media, Tooltip, Badge } from "reactstrap"
import PropTypes from 'prop-types'
import {formatDateMD, formatDate, formatDateLongWithoutYear} from '../../../helpers/formatter.js'

function isInThePast(date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date < today;
}

const TimelineItem = (props) => {

  const [ttop, setttop] = useState(false);

  return (
    <React.Fragment>
      <li className="event-list">
        <div className="event-timeline-dot">
          {isInThePast(props.info.date) && (
            <>
            <i className="bx bx-right-arrow-circle font-size-18 text-primary" />
            </>
          )}
          {!isInThePast(props.info.date)  && (
            <>
            <i className="bx bx-right-arrow-circle font-size-18 text-info" />
            </>
          )}
        </div>
        <Media>
          <div className="me-3">
            {props.info.date && (
              <>
                <Tooltip
                  placement="top"
                  isOpen={ttop}
                  target={"TooltipTop" + props.id}
                  toggle={() => {
                    setttop(!ttop);
                  }}
                >
                  {props.info.date.toString()}
                </Tooltip>
                <h5 className="font-size-14 dotted" id={"TooltipTop" + props.id}>
                  {formatDateLongWithoutYear(props.info.date)}
                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                </h5>
              </>
            )}
            {!props.info.date && (
              <h5 className="font-size-14">
                {"??-??"}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
              </h5>
            )}
          </div>
          <Media body>
            <div>
            {isInThePast(props.info.date) && (
              <>
              <span style={{textDecoration: 'line-through'}}>{props.info.text} </span>
              &nbsp;
              <Badge
                style = {{ marginRight : 2 }}
                className="font-size-12 badge-soft-primary"
                color="info"
                pill
              >
                Completed
              </Badge>
              </>
            )}
            {!isInThePast(props.info.date)  && (
              <>
              <p className={props.info.date ? "" : "text-warning"}>{props.info.text}</p>
              </>
            )}
            </div>
          </Media>
        </Media>
      </li>
    </React.Fragment>
    );
}

TimelineItem.propTypes = {
  info: PropTypes.object,
  id: PropTypes.number,
}

export default TimelineItem;
