import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link, withRouter,useParams, useLocation  } from "react-router-dom";
import classname from "classnames";

// Redux Store
import { toggleLeftmenu } from "../../store/actions"
//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";



const Navbar = props => {

const [governance, setGovernance] = useState(false);
const [governanceP1, setGovernanceP1] = useState(false);
const [governanceP1Voting, setGovernanceP1Voting] = useState(false);

const [governanceP2, setGovernanceP2] = useState(false);
const [governanceP2Voting, setGovernanceP2Voting] = useState(false);

const [algorandStandardAssets, setAlgorandStandardAssets] = useState(false);

const [ui, setui] = useState(false);
const [app, setapp] = useState(false);
const [email, setemail] = useState(false);
const [ecommerce, setecommerce] = useState(false);
const [crypto, setcrypto] = useState(false);
const [project, setproject] = useState(false);
const [task, settask] = useState(false);
const [contact, setcontact] = useState(false);
const [blog, setBlog] = useState(false);
const [component, setcomponent] = useState(false);
const [form, setform] = useState(false);
const [table, settable] = useState(false);
const [chart, setchart] = useState(false);
const [icon, seticon] = useState(false);
const [map, setmap] = useState(false);
const [extra, setextra] = useState(false);
const [invoice, setinvoice] = useState(false);
const [auth, setauth] = useState(false);
const [utility, setutility] = useState(false);
const [isMobile, setIsMobile] = useState(false);

const [governance21, setGovernance21] = useState(false);
const [governance22, setGovernance22] = useState(false);
const [governance23, setGovernance23] = useState(false);
const [governance24, setGovernance24] = useState(false);

const [basePath, setBasePath] = useState('')
const [inputHandle, setInputHandle] = useState('')

useEffect(() => {
  var matchingMenuItem = null;
  var ul = document.getElementById("navigation");
  var items = ul.getElementsByTagName("a");
  for (var i = 0; i < items.length; ++i) {
    if (props.location.pathname === items[i].pathname) {
      matchingMenuItem = items[i];
      break;
    }
  }
  if (matchingMenuItem) {
    //activateParentDropdown(matchingMenuItem);
  }
});

useEffect(() => {
  window.addEventListener("resize", handleResize)
})

const handleResize = () => {
  if (window.innerWidth < 992) {
      setIsMobile(true)
  } else {
    if (isMobile)
    {
      props.toggleLeftmenu(false);
      setGovernance(false);
      setGovernance21(false);
      setIsMobile(false);
    }
  }
}

function activateParentDropdown(item) {
  item.classList.add("active");
  const parent = item.parentElement;
  if (parent) {
    parent.classList.add("active"); // li
    const parent2 = parent.parentElement;
    parent2.classList.add("active"); // li
    const parent3 = parent2.parentElement;
    if (parent3) {
      parent3.classList.add("active"); // li
      const parent4 = parent3.parentElement;
      if (parent4) {
        parent4.classList.add("active"); // li
        const parent5 = parent4.parentElement;
        if (parent5) {
          parent5.classList.add("active"); // li
          const parent6 = parent5.parentElement;
          if (parent6) {
            parent6.classList.add("active"); // li
          }
        }
      }
    }
  }
  return false;
}

const { address } = useParams();
const location = useLocation();

useEffect(() => {
  if (address === undefined)
  {
    setBasePath(location.pathname);
    setInputHandle("");
  }
  else
  {
    setBasePath(location.pathname.substring(0, location.pathname.lastIndexOf('/')));
    setInputHandle(address);
  }

},  [basePath, address]);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
          <Dropdown
            className="navbar-collapse"
            id="topnav-menu-content"
            isOpen={props.leftMenu}
            toggle={() => {
              props.toggleLeftmenu(!props.leftMenu);
            }}
            data-target="#topnav-menu-content"
          >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                    to="/#"
                  className={basePath === "/governance-period-1" || basePath === "/governance-period-1/" ? "nav-link dropdown-toggle arrow-none active" : "nav-link dropdown-toggle arrow-none"}
                  onClick={e => {
                      e.preventDefault();
                      setGovernance21(window.innerWidth <= 992 ? !governance21 : false);
                    }}
                >
                  {props.t("Governance 2021")} {props.menuOpen}
                  <div className="arrow-down"/>
                </Link>
                <div
                    className={classname("dropdown-menu", { show: governance21 })}
                  >
                    <Link
                      to={inputHandle === "" ? "/governance-period-1" : "/governance-period-1/" + inputHandle}
                      className={basePath === "/governance-period-1" || basePath === "/governance-period-1/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                      onClick={e => {
                        props.toggleLeftmenu(!props.leftMenu);
                      }}
                    >
                      {props.t("Governance period #1")}
                    </Link>
                  </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                    to="/#"
                  className={basePath === "/governance-period-2" || basePath === "/governance-period-2/" ||
                  basePath === "/governance-period-3" || basePath === "/governance-period-3/" ||
                  basePath === "/governance-period-4" || basePath === "/governance-period-4/"||
                  basePath === "/governance-period-5" || basePath === "/governance-period-5/" ? "nav-link dropdown-toggle arrow-none active" : "nav-link dropdown-toggle arrow-none"}
                  onClick={e => {
                      e.preventDefault();
                      setGovernance22(window.innerWidth <= 992 ? !governance22 : false);
                    }}
                >
                  {props.t("Governance 2022")} {props.menuOpen}
                  <div className="arrow-down"/>
                </Link>
                <div
                    className={classname("dropdown-menu", { show: governance22 })}
                  >
                  <Link
                    to={inputHandle === "" ? "/governance-period-2" : "/governance-period-2/" + inputHandle}
                    className={basePath === "/governance-period-2" || basePath === "/governance-period-2/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                    onClick={e => {
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                  >
                    {props.t("Governance period #2")}
                  </Link>
                  <Link
                    to={inputHandle === "" ? "/governance-period-3" : "/governance-period-3/" + inputHandle}
                    className={basePath === "/governance-period-3" || basePath === "/governance-period-3/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                    onClick={e => {
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                  >
                    {props.t("Governance period #3")}
                  </Link>
                  <Link
                    to={inputHandle === "" ? "/governance-period-4" : "/governance-period-4/" + inputHandle}
                    className={basePath === "/governance-period-4" || basePath === "/governance-period-4/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                    onClick={e => {
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                  >
                    {props.t("Governance period #4")}
                  </Link>
                  <Link
                    to={inputHandle === "" ? "/governance-period-5" : "/governance-period-5/" + inputHandle}
                    className={basePath === "/governance-period-5" || basePath === "/governance-period-5/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                    onClick={e => {
                      props.toggleLeftmenu(!props.leftMenu);
                    }}
                  >
                    {props.t("Governance period #5")}
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                    to="/#"
                  className={basePath === "/governance-period-6" || basePath === "/governance-period-6/" ||
                  basePath === "/governance-period-7" || basePath === "/governance-period-7/" ||
                  basePath === "/governance-period-8" || basePath === "/governance-period-8/"||
                  basePath === "/governance-period-9" || basePath === "/governance-period-9/" ? "nav-link dropdown-toggle arrow-none active" : "nav-link dropdown-toggle arrow-none"}
                  onClick={e => {
                      e.preventDefault();
                      setGovernance23(window.innerWidth <= 992 ? !governance23 : false);
                    }}
                >
                  {props.t("Governance 2023")} {props.menuOpen}
                  <div className="arrow-down"/>
                </Link>
                <div
                    className={classname("dropdown-menu", { show: governance23 })}
                  >
                    <Link
                      to={inputHandle === "" ? "/governance-period-6" : "/governance-period-6/" + inputHandle}
                      className={basePath === "/governance-period-6" || basePath === "/governance-period-6/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                      onClick={e => {
                        props.toggleLeftmenu(!props.leftMenu);
                      }}
                    >
                      {props.t("Governance period #6")}
                    </Link>
                    <Link
                        to={inputHandle === "" ? "/governance-period-7" : "/governance-period-7/" + inputHandle}
                        className={basePath === "/governance-period-7" || basePath === "/governance-period-7/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                        onClick={e => {
                          props.toggleLeftmenu(!props.leftMenu);
                        }}
                    >
                      {props.t("Governance period #7")}
                    </Link>
                    <Link
                        to={inputHandle === "" ? "/governance-period-8" : "/governance-period-8/" + inputHandle}
                        className={basePath === "/governance-period-8" || basePath === "/governance-period-8/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                        onClick={e => {
                          props.toggleLeftmenu(!props.leftMenu);
                        }}
                    >
                      {props.t("Governance period #8")}
                    </Link>
                    <Link
                        to={inputHandle === "" ? "/governance-period-9" : "/governance-period-9/" + inputHandle}
                        className={basePath === "/governance-period-9" || basePath === "/governance-period-9/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                        onClick={e => {
                          props.toggleLeftmenu(!props.leftMenu);
                        }}
                    >
                      {props.t("Governance period #9")}
                    </Link>
                  </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                    to="/#"
                    className={basePath === "/governance-period-10" || basePath === "/governance-period-10/" ||
                    basePath === "/governance-period-11" || basePath === "/governance-period-11/" ||
                    basePath === "/governance-period-12" || basePath === "/governance-period-12/"||
                    basePath === "/governance-period-13" || basePath === "/governance-period-13/" ? "nav-link dropdown-toggle arrow-none active" : "nav-link dropdown-toggle arrow-none"}
                    onClick={e => {
                      e.preventDefault();
                      setGovernance24(window.innerWidth <= 992 ? !governance24 : false);
                    }}
                >
                  {props.t("Governance 2024")} {props.menuOpen}
                  <div className="arrow-down"/>
                </Link>
                <div
                    className={classname("dropdown-menu", { show: governance24 })}
                >
                  <Link
                      to={inputHandle === "" ? "/governance-period-10" : "/governance-period-10/" + inputHandle}
                      className={basePath === "/governance-period-10" || basePath === "/governance-period-10/" ? "dropdown-item arrow-none active" : "dropdown-item arrow-none"}
                      onClick={e => {
                        props.toggleLeftmenu(!props.leftMenu);
                      }}
                  >
                    {props.t("Governance period #10")}
                  </Link>
                </div>
              </li>
            </ul>
          </Collapse>
          </Dropdown>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {toggleLeftmenu})(withTranslation()(Navbar))
);
