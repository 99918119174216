import React from "react"
import Dashboard from './index.js'
import PropTypes from 'prop-types'

const GovernancePeriod9 = (props) => {
    return (
        <React.Fragment>
            <Dashboard period="governance-period-9" beta={true} addressState={props.addressState} pagePosition={props.pagePosition}  />
        </React.Fragment>
    )
}

GovernancePeriod9.propTypes = {
    addressState: PropTypes.object,
    pagePosition: PropTypes.string
}

export default GovernancePeriod9;
