import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from 'prop-types'


const GovernorsPerGroup = (props) => {
  const [series, setSeries] = useState([{
          name: 'Eligible governors',
          data: []
        },
        {
          name: 'Ineligible governors',
          data: []
        }]);

  const [options, setOptions] = useState({
    chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: !1,
          columnWidth: "40%"
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        shared: true,
        intersect: false,
        marker: { show: !1 },
        theme: false,
      },
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      xaxis: {
        title: { text: "Number of algos commited" },
        categories: ["0-1", "1-10", "10-100", "100-500", "500-1k", "1k-5k", "5k-10k", "10k-100k", "100k-1m", "1m-10m", "10m-50m", "50m-∞"],
        position: 'bot',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        }
      },
      colors: ["#34c38f","#f1b44c"],
      yaxis: {
        title: { text: "Number of governors" },
        labels: {
          formatter: function(value) {
            var val = Math.abs(value)
            if (val >= 1000000000) {
              val = (val / 1000000000).toFixed(0) + ' Bn'
            }
            else if (val >= 1000000) {
              val = (val / 1000000).toFixed(0) + ' M'
            }
            else if (val >= 1000) {
              val = (val / 1000).toFixed(0) + ' K'
            }
            return val
          }
        }
      }
  });

  const getModifiedOptions = (oldOptions, categories) => {
    const modifiedOptions = {
      ...oldOptions,
      xaxis: {
        ...oldOptions.xaxis,
        categories: categories
      },
    };
    return modifiedOptions;
  }

  useEffect(() => {
     if (!props.overviewPeriod.loading)
     {
       let perGroup = props.overviewPeriod.data.governor.per_group;

       setOptions(getModifiedOptions(options, props.overviewPeriod.data.group_names))
       setSeries([{
               name: 'Eligible governors',
               data: perGroup.eligible
             },
             {
               name: 'Ineligible governors',
               data: perGroup.ineligible
             }]);
     }
  }, [props.overviewPeriod.loading])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <p className="text-muted mb-4">
            <i
              className="mdi mdi-account-multiple h2 text-info align-middle mb-0 me-3"
            />{" "}
            Governors Per Group
          </p>
          <div>
            <div id="overview-chart" className="apex-charts" dir="ltr">

                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height="350"
                />
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
    );
};

GovernorsPerGroup.propTypes = {
  overviewPeriod: PropTypes.object
}

export default GovernorsPerGroup;
