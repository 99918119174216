import React from "react"
import { Card, CardBody} from "reactstrap"
import PropTypes from 'prop-types'

const Status = (props) => {
  return (
      <React.Fragment>
        <Card style={{height:150}}>
          <CardBody>
            <p className="text-muted mb-4">
              <i
                  className="mdi mdi-bell h2 text-warning align-middle mb-0 me-3"
              />{" "}
              Status
            </p>
            <div className="text-center m-2">
              <h5>
                <span
                    className={props.addressData === undefined || props.addressData["registration_datetime"] === "" || props.addressData["is_eligible"] === 0 ? "text-danger" : "text-success"}>
                  {props.addressData === undefined || props.addressData["registration_datetime"] === "" ? "Not registered" : (props.addressData["is_eligible"] === 0 ? "Ineligible" : "Eligible")}
                </span>
              </h5>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
  );
}

Status.propTypes = {
  addressData: PropTypes.object,
}

export default Status;
