import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"



const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  pagePosition,
  ...rest
}) => {

    const [addressState, setAddressState] = useState({empty: true, valid: false});
    const addressUpdate = (data) => {
        setAddressState(data)
    }

    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthProtected && !localStorage.getItem("authUser")) {
                    return (
                        <Redirect
                            to={{pathname: "/login", state: {from: props.location}}}
                        />
                    )
                }

                return (
                    <Layout onAddressUpdate={addressUpdate} addressState={addressState}>
                        <Component {...props} addressState={addressState} pagePosition={pagePosition}/>
                    </Layout>
                )
            }}
        />)
}

Authmiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
    pagePosition: PropTypes.string
}

export default Authmiddleware;
