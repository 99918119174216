import React from "react"
import { Redirect } from "react-router-dom"


// Dashboard
import GovernancePeriod1 from "../pages/Dashboard/GovernancePeriod1"
import GovernancePeriod2 from "../pages/Dashboard/GovernancePeriod2"
import GovernancePeriod3 from "../pages/Dashboard/GovernancePeriod3"
import GovernancePeriod4 from "../pages/Dashboard/GovernancePeriod4"
import GovernancePeriod5 from "../pages/Dashboard/GovernancePeriod5"
import GovernancePeriod6 from "../pages/Dashboard/GovernancePeriod6"
import GovernancePeriod7 from "../pages/Dashboard/GovernancePeriod7"
import GovernancePeriod8 from "../pages/Dashboard/GovernancePeriod8"
import GovernancePeriod9 from "../pages/Dashboard/GovernancePeriod9"
import GovernancePeriod10 from "../pages/Dashboard/GovernancePeriod10"

const getGovernancePeriod = (index, component) => {
  let newAdded = [
    { path: "/governance-period-" + index, component: component },
    { path: "/governance-period-" + index + "/account/:address", component: component, pagePosition:"account"},
    { path: "/governance-period-" + index + "/:address", component: component },
  ]
  return newAdded;
}

const authProtectedRoutes = []

authProtectedRoutes.push(...getGovernancePeriod(1, GovernancePeriod1))
authProtectedRoutes.push(...getGovernancePeriod(2, GovernancePeriod2))
authProtectedRoutes.push(...getGovernancePeriod(3, GovernancePeriod3))
authProtectedRoutes.push(...getGovernancePeriod(4, GovernancePeriod4))
authProtectedRoutes.push(...getGovernancePeriod(5, GovernancePeriod5))
authProtectedRoutes.push(...getGovernancePeriod(6, GovernancePeriod6))
authProtectedRoutes.push(...getGovernancePeriod(7, GovernancePeriod7))
authProtectedRoutes.push(...getGovernancePeriod(8, GovernancePeriod8))
authProtectedRoutes.push(...getGovernancePeriod(9, GovernancePeriod9))
authProtectedRoutes.push(...getGovernancePeriod(10, GovernancePeriod10))

authProtectedRoutes.push(
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/governance-period-10" />}
)

const publicRoutes = [
]

export { publicRoutes, authProtectedRoutes }
