import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from 'prop-types'

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

const VotesPerDay = (props) => {
  const [seriesCommitedStake, setSeriesCommitedStake] = useState([
    {
       name: "Algos",
       data: [],
    },
  ]);
  const [seriesGovernors, setSeriesGovernors] = useState([
    {
       name: "Governors",
       data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: { id: props.topicIndex + '-456', group:props.topicIndex + '-perDate', toolbar: { show: false, tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
      }}
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2 },
    xaxis: {
      title: { text: "Date" },
      type: "datetime",
      tickAmount: 1,
    },
    yaxis: {
      title: { text: "Number of algos" },
      labels: {
        formatter: function(value) {
          var val = Math.abs(value)
          if (val >= 1000000000) {
            val = (val / 1000000000).toFixed(2) + ' Bn'
          }
          else if (val >= 1000000) {
            val = (val / 1000000).toFixed(0) + ' M'
          }
          else if (val >= 1000) {
            val = (val / 1000).toFixed(0) + ' K'
          }
          return val
        },
        minWidth: 40
      },
      tickAmount: 5,
    },
    tooltip: { x: { show: false}, y: {
      formatter: function(value) {
        var val = Math.abs(value)
        return val.toLocaleString('en-US', {maximumFractionDigits: 0})
      }
    }, theme: false},
    colors: ["#556ee6", "#34c38f", "#f1b44c"],

  });

  const [options1, setOptions1] = useState({
    chart: { toolbar: { show: false, tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
      } }, id: props.topicIndex + '-123', group:props.topicIndex + '-perDate',
    },
    dataLabels: { enabled: !1 },
    stroke: { curve: "smooth", width: 2 },
    markers: { size: 0, style: "hollow" },
    xaxis: {
      title: { text: "Date" },
      type: "datetime",
      tickAmount: 1,
    },
    yaxis: {
      title: { text: "Number of governors" },
      labels: {
        show: true,
        formatter: function(value) {
          var val = Math.abs(value)
          if (val >= 1000000000) {
            val = (val / 1000000000).toFixed(0) + ' Bn'
          }
          else if (val >= 1000000) {
            val = (val / 1000000).toFixed(0) + ' M'
          }
          else if (val >= 1000) {
            val = (val / 1000).toFixed(0) + ' K'
          }
          return val
        },
        minWidth: 40
      },
      tickAmount: 6,
    },
    tooltip: { x: { show: false}, y: {
      formatter: function(value) {
        var val = Math.abs(value)
        return val.toLocaleString('en-US', {maximumFractionDigits: 0})
      },
    }, theme: false},
    colors: ["#556ee6", "#34c38f", "#f1b44c"]
  });


  useEffect(() => {
         if (!props.foundationVoting.loading && !props.votingPeriod.loading && !props.overviewPeriod.loading && !props.foundationPeriod.loading)
         {
            let voting_start_datetime = new Date(props.foundationVoting.data["voting_start_datetime"])
            let voting_end_datetime = new Date(props.foundationVoting.data["voting_end_datetime"])

            let start_datetime = new Date(props.foundationPeriod.data.start_datetime);
            let difference =  voting_start_datetime.getTime() - start_datetime.getTime();
            var days = Math.ceil(difference / (1000 * 3600 * 24)) ;
            let commitedStakePerDay = props.overviewPeriod.data.committed_stake.interval_per_day;
            let governorPerDay = props.overviewPeriod.data.governor.interval_per_day;
            let commitedStake = 0
            let governors = 0
            for (let i = 0; i < days; ++i) {
              commitedStake += commitedStakePerDay[i]
              governors += governorPerDay[i]
            }

            let allDates = getDates(voting_start_datetime, voting_end_datetime)
            let adat = []
            let bdat = []
            let nonedat = []
            let agoverdat = []
            let bgoverdat = []
            let nonegoverdat = []

            let votingInfo = props.votingPeriod.data.voting[props.votingIndex]["topics"][props.topicIndex]

            let totala = 0
            let totalb = 0
            let totalnone = 0
            let totalgova = 0
            let totalgovb = 0
            let totalgovnone = 0

            let lastItem = -1
            if (votingInfo) {
              for (let i = 0; i < allDates.length; ++i) {
                let n = false;
                if (votingInfo["voting_per_day_algo_a"].length > i)
                {
                  totala += votingInfo["voting_per_day_algo_a"][i]
                  adat.push([allDates[i], totala / 1000000])
                  n = true;
                }
                else {
                  adat.push([allDates[i]])
                }
                if (votingInfo["voting_per_day_algo_b"].length > i)
                {
                  totalb += votingInfo["voting_per_day_algo_b"][i]
                  bdat.push([allDates[i], totalb / 1000000])
                  n = true;
                }
                else {
                  bdat.push([allDates[i]])
                }
                if (votingInfo["voting_per_day_governors_a"].length > i)
                {
                  totalgova += votingInfo["voting_per_day_governors_a"][i]
                  agoverdat.push([allDates[i], totalgova])
                  n = true;
                }
                else {
                  agoverdat.push([allDates[i]])
                }
                if (votingInfo["voting_per_day_governors_b"].length > i)
                {
                  totalgovb += votingInfo["voting_per_day_governors_b"][i]
                  bgoverdat.push([allDates[i], totalgovb])
                  n = true;
                }
                else {
                  bgoverdat.push([allDates[i]])
                }
                if (n) {
                  commitedStake += commitedStakePerDay[days + i]
                  governors += governorPerDay[days + i]
                  totalnone = commitedStake - totala - totalb
                  totalgovnone = governors - totalgova - totalgovb
                  nonedat.push([allDates[i], totalnone / 1000000])
                  nonegoverdat.push([allDates[i], totalgovnone])
                  lastItem = i
                }
                else {
                  if (lastItem == -10)
                  {
                    lastItem = i - 1
                  }
                  nonedat.push([allDates[i]])
                  nonegoverdat.push([allDates[i]])
                }
              }
              if (lastItem > -1) {
                nonedat[lastItem] = [allDates[lastItem], votingInfo["total_voted_none_algos"] / 1000000]
                nonegoverdat[lastItem] = [allDates[lastItem], votingInfo["total_voted_none_governors"]]
              }
            }


            setSeriesCommitedStake([{
               name: "Voted A",
               data: adat,
            },
            {
               name: "Voted B",
               data: bdat,
            },
            {
               name: "Not Voted",
               data: nonedat,
            }])
            setSeriesGovernors([{
               name: "Voted A",
               data: agoverdat,
            },
            {
               name: "Voted B",
               data: bgoverdat,
            },
            {
               name: "Not Voted",
               data: nonegoverdat,
            }])
         }
  }, [props.foundationVoting.loading, props.votingPeriod.loading, props.overviewPeriod.loading, props.foundationPeriod.loading])

  useEffect(() => {
    //window.dispatchEvent(new Event('resize'));
  }, [props.refresh])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
        <p className="text-muted mb-4">
          <i
            className="mdi mdi-chart-line h2 text-info align-middle mb-0 me-3"
          />{" "}
          Votes Per Day
        </p>
        <div>
          <div id="overview-chart" className="apex-charts" dir="ltr">
            <div id="overview-chart-timeline">
            <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
              <p className="text-muted mt-4 mb-0">
                Number of ALGOs voted per day
              </p>
            </div>
              <ReactApexChart
                options={options}
                series={seriesCommitedStake}
                type="line"
                height={350}
              />
            </div>
            <div id="overview-chart-timeline">
              <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                <p className="text-muted mb-0">
                  Number of governors voted per day
                </p>
              </div>
              <ReactApexChart
                options={options1}
                series={seriesGovernors}
                type="line"
                height={350}
              />
            </div>
          </div>
        </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};



VotesPerDay.propTypes = {
  TopicPeriod : PropTypes.object,
  votingPeriod : PropTypes.object,
  foundationVoting: PropTypes.object,
  foundationPeriod: PropTypes.object,
  overviewPeriod: PropTypes.object,
  refresh: PropTypes.bool,
  votingIndex: PropTypes.number,
  topicIndex: PropTypes.number,
}

export default VotesPerDay;
