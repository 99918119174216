import React, { useState, useEffect } from "react"
import {  Row, Col, Card, CardBody, Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types'
import { formatDate } from '../../../helpers/formatter.js'

const GovernorsOverview = (props) => {
  const [series, setSeries] = useState([{ name: "Governors", data: [] }])
  const [today, setToday] = useState(0)
  const [total, setTotal] = useState(0)

  const [btnprimary1, setBtnprimary1] = useState(false);

  const [governorsstandardtop, setgovernorsstandardtop] = useState(false);
  const [governorsdefitop, setgovernorsdefitop] = useState(false);

  const [generalData, setGeneralData] = useState({
    title: "General Governance",
    tooltip : "All governors excluding DeFi governors.",
    index : 1
  });

  const [defiData, setDefiData] = useState({
    title: "DeFi Governance",
    tooltip : "All governors via the different DeFi platforms (such as Vault, Liquid Governance and Liquidity provider).",
    index : 2
  });

  const [lgData, setLgData] = useState({
    title: "Liquid Governance",
    tooltip : "Liquid Governance is a unique feature that allows ALGOs holders to participate in the Algorand Governance while maintaining their liquidity.",
    index : 3
  });

  const [lpData, setLpData] = useState({
    title: "Liquidity Provider Governance",
    tooltip : "All governors who committed DEX LP tokens.",
    index : 4
  });

  const [secondData, setSecondData] = useState({
    is_loaded : false
  });

  const onGeneralClick = () => {
    setSecondData(generalData);
  };
  const onDefiClick = () => {
    setSecondData(defiData);
  };
  const onLgClick = () => {
    setSecondData(lgData);
  };
  const onLpClick = () => {
    setSecondData(lpData);
  };

  const [options, setOptions] = useState({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#34c38f"],
    stacked: false,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      fixed: { enabled: true, position: 'topRight',  offsetY: -75,offsetX: 20 },
      marker: { show: !1 },
      theme: false,
    },
    xaxis: {
      type: "categories",
      categories: [
        "","","","","","","","","",""
      ]
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          let convertValue = value;
          let plus = convertValue > 0 ? "+" : "";
          return plus + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(convertValue);
        }
      },
      reversed: false
    }
  });

  const [optionsDefi, setOptionsDefi] = useState({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#a150a0"],
    stacked: false,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      fixed: { enabled: true, position: 'topRight',  offsetY: -75,offsetX: 20 },
      marker: { show: !1 },
      theme: false,
    },
    xaxis: {
      type: "categories",
      categories: [
        "","","","","","","","","",""
      ]
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          let convertValue = value;
          let plus = convertValue > 0 ? "+" : "";
          return plus + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(convertValue);
        }
      },
      reversed: false
    }
  });

  const getModifiedReversed = (oldOptions, reversed) => {
    const modifiedOptions = {
      ...oldOptions,
      yaxis: {
        ...oldOptions.yaxis,
        reversed: reversed
      },
    };
    return modifiedOptions;
  }

  let categories = [];
  for (var i=0; i<20; i++) {
    var date = new Date();
    date.setDate(date.getDate() - i);
    categories.push("Last 20 days - " + formatDate(date));
  }

  options["xaxis"]["categories"] = categories.reverse();
  optionsDefi["xaxis"]["categories"] = categories

  function getCommitedStakeData(governor, optio) {
    const len = governor.interval_per_day.length;

    const intervalPerDay = governor.interval_per_day.slice(Math.min(len,20) * -1);
    let newData = []
    for (var i = intervalPerDay.length - 1; i >= 0; i--) {
      newData.push(Number((intervalPerDay[i]).toFixed(0)));
    }

    while (newData.length < 20)
    {
      newData.push(0);
    }

    let p1 = governor.start_count;
    let p2 = governor.current_count;
    let today = 0;
    if (newData.length > 0)
    {
      today = newData[0]
    }
    let total = p2 - p1;
    let series = [{ name: "Governors", data: newData.reverse()}];

    let result = true;
    for (let i = 0; i < newData.length; i++) {
         if (newData[i] > 0) {
             result = false;
             break;
         }
     }

    let op = getModifiedReversed(optio, result);
    let governor_data = {
      governor_current_count : governor.current_count,
      today : today,
      total : total,
      series : series,
      option : op,
      is_loaded : true
    }
    return governor_data;
  }

  const updateDataOptions = (oldOptions, updateOptions) => {
    return { ...oldOptions, ...updateOptions };
  }

  function substractCommitedStakeData(data_1, data_2) {
    let newData = []
    for (var i = 0; i < data_1.series[0].data.length; i++) {
      newData.push(data_1.series[0].data[i] - data_2.series[0].data[i])
    }
    let series = [{ name: "Governors", data: newData}];

    let governor_data = {
      governor_current_count : data_1.governor_current_count - data_2.governor_current_count,
      today : data_1.today - data_2.today,
      total : data_1.total - data_2.total,
      series : series,
      option : data_1.option,
      is_loaded : true
    }
    return governor_data;
  }

  useEffect(() => {
         if (!props.overviewPeriod.loading)
         {
           const len = props.overviewPeriod.data.governor.interval_per_day.length;

           const intervalPerDay = props.overviewPeriod.data.governor.interval_per_day.slice(Math.min(len,20) * -1);
           let newData = []
           for (var i = intervalPerDay.length - 1; i >= 0; i--) {
             newData.push(Number((intervalPerDay[i]).toFixed(0)));
           }

           while (newData.length < 20)
           {
             newData.push(0);
           }

           let p1 = props.overviewPeriod.data.governor.start_count;
           let p2 = props.overviewPeriod.data.governor.current_count;
           if (newData.length > 0)
           {
             setToday(newData[0]);
           }
           setTotal(p2 - p1);
           setSeries([{ name: "Governors", data: newData.reverse()}]);

           let result = true;
           for (let i = 0; i < newData.length; i++) {
                if (newData[i] > 0) {
                    result = false;
                    break;
                }
            }

            setOptions(getModifiedReversed(options, result));

            let allDataOption = updateDataOptions(generalData, getCommitedStakeData(props.overviewPeriod.data.governor, optionsDefi))
            let defiDataOption = updateDataOptions(defiData, getCommitedStakeData(props.overviewPeriod.data.defi_governor, optionsDefi))
            let lpDataOption = updateDataOptions(lpData, getCommitedStakeData(props.overviewPeriod.data.assets_governor, optionsDefi))

            setDefiData(defiDataOption)
            setGeneralData(updateDataOptions(generalData, substractCommitedStakeData(allDataOption, defiDataOption)))
            setLgData(updateDataOptions(lgData, substractCommitedStakeData(defiDataOption, lpDataOption)))
            setLpData(lpDataOption)
         }
     }, [props.overviewPeriod.loading])

     useEffect(() => {
       setSecondData(defiData);
     }, [defiData])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <p className="text-muted mb-4">
            <i
              className="mdi mdi-account-multiple h2 text-success align-middle mb-0 me-3"
            />{" "}
            Governors
          </p>

          <Row>
            <Col xs="12" sm="12" xl="6">
              <div className="text-center m-3">
                <h5 className="font-size-15"><Tooltip
                      placement="top"
                      isOpen={governorsstandardtop}
                      target="governorsstandardtopTooltipTop"
                      toggle={() => {
                        setgovernorsstandardtop(!governorsstandardtop);
                      }}
                      >
                      {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                        <>
                        All governors (General and DeFi).
                        </>
                      )}
                </Tooltip>Governance <i className="fas fa-info-circle" id="governorsstandardtopTooltipTop"/></h5>
                {props.overviewPeriod.loading && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
                {!props.overviewPeriod.loading && (
                  <h5 style={{ fontWeight: 'bold', margin:"20px" }}>{ new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(props.overviewPeriod.data.governor.current_count)}
                  </h5>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                  <span className={today == 0 ? "badge badge-soft-info font-size-12 me-1" : today > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (today > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(today) }
                  </span>{" "}
                  From yesterday
                </div>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                  <span className={total == 0 ? "badge badge-soft-info font-size-12 me-1" : total > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (total > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(total) }
                  </span>{" "}
                  From start period
                </div>
                )}
              </div>
              <div className="mx-5 my-4">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={61}
                  className="apex-charts"
                />
              </div>
            </Col>
            <Col xs="12" sm="12" xl="6">
            <Tooltip
                  placement="top"
                  isOpen={governorsdefitop}
                  target="governorsdefitopTooltipTop"
                  toggle={() => {
                    setgovernorsdefitop(!governorsdefitop);
                  }}
                  >
                    {secondData.tooltip}
              </Tooltip>
              <Dropdown
                isOpen={btnprimary1}
                toggle={() => setBtnprimary1(!btnprimary1)}
                className="text-center"
                style={{marginTop:7, marginBottom:-10, marginLeft:10}}
              >
                <DropdownToggle caret
                  color="primary"
                  className="btn btn-primary">
                  {secondData.is_loaded && (
                    <>
                  {secondData.title}  <i className="mdi mdi-chevron-down" />
                  </>
                  )}
                </DropdownToggle>
                <i style={{marginLeft:4}} className="fas fa-info-circle" id="governorsdefitopTooltipTop"/>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem className={secondData.index == 1 ? "active" : ""} onClick={onGeneralClick}>General Governance</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className={secondData.index == 2 ? "active" : ""} onClick={onDefiClick}>DeFi Governance</DropdownItem>
                  <DropdownItem className={secondData.index == 3 ? "active" : ""} onClick={onLgClick}>&nbsp;&nbsp;&nbsp;&nbsp;Liquid Governance</DropdownItem>
                  <DropdownItem className={secondData.index == 4 ? "active" : ""} onClick={onLpClick}>&nbsp;&nbsp;&nbsp;&nbsp;Liquidity Provider Governance</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <div className="text-center">
                {!secondData.is_loaded && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
                {secondData.is_loaded && (
                  <h5 style={{ fontWeight: 'bold', margin:"20px" }}>{ new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(secondData.governor_current_count)}
                  </h5>
                )}
                {!secondData.is_loaded && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {secondData.is_loaded && (
                  <div>
                    <span className={secondData.today == 0 ? "badge badge-soft-info font-size-12 me-1" : secondData.today > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                      {" "}
                      { (secondData.today > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(secondData.today) }
                    </span>{" "}
                    From yesterday
                  </div>
                )}
                {!secondData.is_loaded && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {secondData.is_loaded && (
                  <div>
                    <span className={secondData.total == 0 ? "badge badge-soft-info font-size-12 me-1" : secondData.total > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                      {" "}
                      { (secondData.total > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(secondData.total) }
                    </span>{" "}
                    From start period
                  </div>
                )}
              </div>
              <div className="mx-5 my-4">
              {secondData.is_loaded && (
                  <ReactApexChart
                    options={secondData.option}
                    series={secondData.series}
                    type="bar"
                    height={61}
                    className="apex-charts"
                  />
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

GovernorsOverview.propTypes = {
  foundationPeriod: PropTypes.object,
  overviewPeriod: PropTypes.object
}

export default GovernorsOverview;
