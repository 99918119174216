import React from "react"
import Dashboard from './index.js'
import PropTypes from 'prop-types'

const GovernancePeriod3 = (props) => {
  return (
    <React.Fragment>
      <Dashboard period="governance-period-3" beta={false} addressState={props.addressState} pagePosition={props.pagePosition}  />
    </React.Fragment>
  )
}

GovernancePeriod3.propTypes = {
    addressState: PropTypes.object,
    pagePosition: PropTypes.string
}

export default GovernancePeriod3;
