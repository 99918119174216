import React, { useState, useEffect } from "react"
import {  Row, Col, Card, CardBody, Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types'
import { formatDate } from '../../../helpers/formatter.js'

import {ReactComponent as AlgorandSmall} from "../../../assets/images/algorand_logo_mark_black.svg"

const CommittedAlgosOverview = (props) => {
  const [series, setSeries] = useState([{ name: "Committed ALGOs", data: [] }]);
  const [today, setToday] = useState(0);
  const [total, setTotal] = useState(0);
  const [btnprimary1, setBtnprimary1] = useState(false);

  const [committedstandardtop, setcommittedstandardtop] = useState(false);
  const [committeddefitop, setcommitteddefitop] = useState(false);

  const [generalData, setGeneralData] = useState({
    title: "General Governance",
    tooltip : "All committed ALGOs excluding DeFi commitments.",
    index : 1
  });

  const [defiData, setDefiData] = useState({
    title: "DeFi Governance",
    tooltip : "All committed ALGOs via the different DeFi platforms (such as Vault, Liquid Governance and Liquidity provider).",
    index : 2
  });

  const [lgData, setLgData] = useState({
    title: "Liquid Governance",
    tooltip : "Liquid Governance is a unique feature that allows ALGOs holders to participate in the Algorand Governance while maintaining their liquidity.",
    index : 3
  });

  const [lpData, setLpData] = useState({
    title: "Liquidity Provider Governance",
    tooltip : "Committed DEX LP tokens in ALGOs value.",
    index : 4
  });

  const [secondData, setSecondData] = useState({
    is_loaded : false
  });

  const onGeneralClick = () => {
    setSecondData(generalData);
  };
  const onDefiClick = () => {
    setSecondData(defiData);
  };
  const onLgClick = () => {
    setSecondData(lgData);
  };
  const onLpClick = () => {
    setSecondData(lpData);
  };
  const [options, setOptions] = useState({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#50a5f1"],
    stacked: false,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      fixed: { enabled: true, position: 'topRight',  offsetY: -75,offsetX: 20 },
      marker: { show: !1 },
      theme: false,
    },
    xaxis: {
      type: "categories",
      categories: [
        "","","","","","","","","",""
      ]
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          let convertValue = value;
          let plus = convertValue > 0 ? "+" : "";
          return plus + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(convertValue) + " algos";
        }
      },
      reversed: false,
    }
  });

  const [optionsDefi, setOptionsDefi] = useState({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#a150a0"],
    stacked: false,
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      fixed: { enabled: true, position: 'topRight',  offsetY: -75,offsetX: 20 },
      marker: { show: !1 },
      theme: false,
    },
    xaxis: {
      type: "categories",
      categories: [
        "","","","","","","","","",""
      ]
    },
    yaxis: {
      labels: {
        show: false,
        formatter: function (value) {
          let convertValue = value;
          let plus = convertValue > 0 ? "+" : "";
          return plus + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(convertValue) + " algos";
        }
      },
      reversed: false,
    }
  });

  const updateDataOptions = (oldOptions, updateOptions) => {
    return { ...oldOptions, ...updateOptions };
  }

  const getModifiedReversed = (oldOptions, reversed) => {
    const modifiedOptions = {
      ...oldOptions,
      yaxis: {
        ...oldOptions.yaxis,
        reversed: reversed
      },
    };
    return modifiedOptions;
  }

  let categories = [];
  for (var i=0; i<20; i++) {
    var date = new Date();
    date.setDate(date.getDate() - i);
    categories.push("Last 20 days - " + formatDate(date));
  }

  options["xaxis"]["categories"] = categories.reverse();
  optionsDefi["xaxis"]["categories"] = categories;

  function getCommitedStakeData(committed_stake, optio) {
    const len = committed_stake.interval_per_day.length;

    const intervalPerDay = committed_stake.interval_per_day.slice(Math.min(len,20) * -1);
    let newData = []
    for (var i = intervalPerDay.length - 1; i >= 0; i--) {
      newData.push(Number((intervalPerDay[i] / 1000000).toFixed(0)));
    }

    while (newData.length < 20)
    {
      newData.push(0);
    }

    let p1 = committed_stake.start_count;
    let p2 = committed_stake.current_count;

    let today = 0;
    if (newData.length > 0)
    {
      today = newData[0]
    }
    let total = (p2 - p1) / 1000000;
    let series = [{ name: "Committed algos", data: newData.reverse()}];

    let result = true;
    for (let i = 0; i < newData.length; i++) {
         if (newData[i] > 0) {
             result = false;
             break;
         }
    }
    let op = getModifiedReversed(optio, result);
    let commited_stake_data = {
      committed_stake_current_count : committed_stake.current_count,
      today : today,
      total : total,
      series : series,
      option : op,
      is_loaded : true
    }
    return commited_stake_data;
  }

  function substractCommitedStakeData(data_1, data_2) {
    let newData = []
    for (var i = 0; i < data_1.series[0].data.length; i++) {
      newData.push(data_1.series[0].data[i] - data_2.series[0].data[i])
    }
    let series = [{ name: "Committed algos", data: newData}];

    let commited_stake_data = {
      committed_stake_current_count : data_1.committed_stake_current_count - data_2.committed_stake_current_count,
      today : data_1.today - data_2.today,
      total : data_1.total - data_2.total,
      series : series,
      option : data_1.option,
      is_loaded : true
    }

    return commited_stake_data;
  }

  useEffect(() => {
         if (!props.overviewPeriod.loading)
         {
           const len = props.overviewPeriod.data.committed_stake.interval_per_day.length;

           const intervalPerDay = props.overviewPeriod.data.committed_stake.interval_per_day.slice(Math.min(len,20) * -1);
           let newData = []
           for (var i = intervalPerDay.length - 1; i >= 0; i--) {
             newData.push(Number((intervalPerDay[i] / 1000000).toFixed(0)));
           }

           while (newData.length < 20)
           {
             newData.push(0);
           }

           let p1 = props.overviewPeriod.data.committed_stake.start_count;
           let p2 = props.overviewPeriod.data.committed_stake.current_count;

           if (newData.length > 0)
           {
             setToday(newData[0]);
           }
           setTotal((p2 - p1) / 1000000);
           setSeries([{ name: "Committed algos", data: newData.reverse()}]);

           let result = true;
           for (let i = 0; i < newData.length; i++) {
                if (newData[i] > 0) {
                    result = false;
                    break;
                }
            }
           setOptions(getModifiedReversed(options, result));

           let allDataOption = updateDataOptions(generalData, getCommitedStakeData(props.overviewPeriod.data.committed_stake, optionsDefi))
           let defiDataOption = updateDataOptions(defiData, getCommitedStakeData(props.overviewPeriod.data.defi_committed_stake, optionsDefi))
           let lpDataOption = updateDataOptions(lpData, getCommitedStakeData(props.overviewPeriod.data.assets_committed_stake, optionsDefi))

           setDefiData(defiDataOption)
           setGeneralData(updateDataOptions(generalData, substractCommitedStakeData(allDataOption, defiDataOption)))
           setLgData(updateDataOptions(lgData, substractCommitedStakeData(defiDataOption, lpDataOption)))
           setLpData(lpDataOption)
         }
     }, [props.overviewPeriod.loading])

  useEffect(() => {
    setSecondData(defiData);
  }, [defiData])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <p className="text-muted">
            <i
              className="mdi mdi-circle-multiple h2 text-info align-middle me-3"
            />{" "}
            Committed ALGOs
          </p>
          <Row>
            <Col xs="12" sm="12" xl="6">
              <div className="text-center m-3">
                <h5 className="font-size-15"><Tooltip
                      placement="top"
                      isOpen={committedstandardtop}
                      target="commitedstandardtopTooltipTop"
                      toggle={() => {
                        setcommittedstandardtop(!committedstandardtop);
                      }}
                      >
                      {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                        <>
                        All committed ALGOs (General and DeFi).
                        </>
                      )}
                </Tooltip>Governance <i className="fas fa-info-circle" id="commitedstandardtopTooltipTop"/></h5>
                {props.overviewPeriod.loading && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
                {!props.overviewPeriod.loading && (
                  <h5 style={{ fontWeight: 'bold', margin:"20px" }}>{ new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(props.overviewPeriod.data.committed_stake.current_count / 1000000)}
                    <AlgorandSmall className="icon-style"/>
                  </h5>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                  <span className={today == 0 ? "badge badge-soft-info font-size-12 me-1" : today > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (today > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(today)}
                      <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                  </span>{" "}
                  From yesterday
                </div>
                )}
                {props.overviewPeriod.loading && props.foundationPeriod.loading && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                <div>
                  <span className={total == 0 ? "badge badge-soft-info font-size-12 me-1" : total > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (total > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(total)}
                      <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                  </span>{" "}
                  From start period
                </div>
                )}
              </div>
              <div className="mx-5 my-4">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height={61}
                  className="apex-charts"
                />
              </div>
            </Col>
            <Col xs="12" sm="12" xl="6">
              <Tooltip
                    placement="top"
                    isOpen={committeddefitop}
                    target="commiteddefitopTooltipTop"
                    toggle={() => {
                      setcommitteddefitop(!committeddefitop);
                    }}
                    >
                    {secondData.tooltip}
              </Tooltip>
              <Dropdown
                isOpen={btnprimary1}
                toggle={() => setBtnprimary1(!btnprimary1)}
                className="text-center"
                style={{marginTop:7, marginBottom:-10, marginLeft:10}}
              >
                <DropdownToggle caret
                  color="primary"
                  className="btn btn-primary">
                  {secondData.is_loaded && (
                    <>
                  {secondData.title}  <i className="mdi mdi-chevron-down" />
                  </>
                  )}
                </DropdownToggle>
                <i style={{marginLeft:4}} className="fas fa-info-circle" id="commiteddefitopTooltipTop"/>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem className={secondData.index == 1 ? "active" : ""} onClick={onGeneralClick}>General Governance</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className={secondData.index == 2 ? "active" : ""} onClick={onDefiClick}>DeFi Governance</DropdownItem>
                  <DropdownItem className={secondData.index == 3 ? "active" : ""} onClick={onLgClick}>&nbsp;&nbsp;&nbsp;&nbsp;Liquid Governance</DropdownItem>
                  <DropdownItem className={secondData.index == 4 ? "active" : ""} onClick={onLpClick}>&nbsp;&nbsp;&nbsp;&nbsp;Liquidity Provider Governance</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <div className="text-center">
                {!secondData.is_loaded && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
                {secondData.is_loaded && (
                  <h5 style={{ fontWeight: 'bold', margin:"20px" }}>{ new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(secondData.committed_stake_current_count / 1000000)}
                    <AlgorandSmall className="icon-style"/>
                  </h5>
                )}
                {!secondData.is_loaded && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {secondData.is_loaded && (
                <div>
                  <span className={secondData.today == 0 ? "badge badge-soft-info font-size-12 me-1" : secondData.today > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                    {" "}
                    { (secondData.today > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(secondData.today)}
                      <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                  </span>{" "}
                  From yesterday
                </div>
                )}
                {!secondData.is_loaded && <p className="text-muted text-truncate mb-0 placeholder-glow"><span className="placeholder col-10"></span></p>}
                {secondData.is_loaded && (
                  <div>
                    <span className={secondData.total == 0 ? "badge badge-soft-info font-size-12 me-1" : secondData.total > 0 ? "badge badge-soft-success font-size-12 me-1" : "badge badge-soft-danger font-size-12 me-1"}>
                      {" "}
                      { (secondData.total > 0 ? "+" : "") + new Intl.NumberFormat('en-US', {maximumFractionDigits: 0}).format(secondData.total)}
                        <AlgorandSmall className="icon-style" style={{ height: 24}}/>
                    </span>{" "}
                    From start period
                  </div>
                )}
              </div>
              <div className="mx-5 my-4">
              {secondData.is_loaded && (
                  <ReactApexChart
                    options={secondData.option}
                    series={secondData.series}
                    type="bar"
                    height={61}
                    className="apex-charts"
                  />
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

CommittedAlgosOverview.propTypes = {
  foundationPeriod: PropTypes.object,
  overviewPeriod: PropTypes.object
}

export default CommittedAlgosOverview;
