import React, { useState, useEffect, useRef } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import PropTypes from 'prop-types'

import TimelineItem from "./TimelineItem"
import { formatDateMD } from '../../../helpers/formatter.js'

const PeriodTimeline = (props) => {
  const [timelineItems, setTimelineItems] = useState([]);

  useEffect(() => {
         if (!props.foundationPeriod.loading)
         {
           let newTimelineItems = [];
           newTimelineItems.push({sub: false, text:"Period start",date:new Date(props.foundationPeriod.data.start_datetime)});
           newTimelineItems.push({sub: false, text:"Registration end",date:new Date(props.foundationPeriod.data.registration_end_datetime)});
           let voting_sessions = props.foundationPeriod.data.voting_sessions;

          if (voting_sessions.length == 0) {
            newTimelineItems.push({sub: false, text:"Voting not announced"});
          }

           for (let i = 0; i < voting_sessions.length; ++i) {
             let votingInfo = [];
             votingInfo.push({sub: false, text:"Voting start - " + voting_sessions[i].title,date:new Date(voting_sessions[i].voting_start_datetime)});
             votingInfo.push({sub: false, text:"Voting end",date:new Date(voting_sessions[i].voting_end_datetime)});
             votingInfo.push({sub: false, text:"Cooldown phase start",date:new Date(voting_sessions[i].voting_end_datetime)});
             votingInfo.push({sub: false, text:"Cooldown phase end",date:new Date(voting_sessions[i].cooldown_end_datetime)});
             newTimelineItems.push({sub: true, subItems : votingInfo });
           }

           newTimelineItems.push({sub: false, text:"Period End",date:new Date(props.foundationPeriod.data.end_datetime)});


           setTimelineItems(newTimelineItems)
         }
     }, [props.foundationPeriod.loading]);

  const messagesEndRef = useRef(null)

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <p className="text-muted mb-4">
            <i
              className="mdi mdi-timeline h2 text-info align-middle mb-0 me-3"
            />{" "}
            Governance Period Timeline
          </p>
          Governance works in cycles. Each cycle lasts three months and contains a sign up phase, a voting phase and a rewards phase.
          <SimpleBar style={{  marginTop:"30px" }}>
            {props.foundationPeriod.loading && <p className="text-muted mb-2 placeholder-glow"><span className="placeholder col-6"></span></p>}
            {!props.foundationPeriod.loading && (
              <ul className="verti-timeline list-unstyled">
                {timelineItems.map((item, i) => (
                  item.sub ? <ul key={((i+1)*1000)} className="verti-timeline list-unstyled mb-4 mt-1"> {item.subItems.map((subitem, j) => ( <TimelineItem key={j+((i+1)*100)} id={j+((i+1)*10)} info={subitem} /> ))}  </ul> : <TimelineItem key={i} id={i} info={item} />
                ))}
              </ul>
            )}
            <div ref={messagesEndRef} />
          </SimpleBar>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

PeriodTimeline.propTypes = {
  foundationPeriod: PropTypes.object
}

export default PeriodTimeline;
