import React from "react"
import Dashboard from './index.js'
import PropTypes from 'prop-types'

const GovernancePeriod6 = (props) => {
  return (
    <React.Fragment>
      <Dashboard period="governance-period-6" beta={true} addressState={props.addressState} pagePosition={props.pagePosition}  />
    </React.Fragment>
  )
}

GovernancePeriod6.propTypes = {
    addressState: PropTypes.object,
    pagePosition: PropTypes.string
}

export default GovernancePeriod6;
