import React, {useState, useEffect} from "react"
import { Row, Col, Card, CardBody, Badge, Button, Tooltip } from "reactstrap"
import {formatDate, formatDateLong} from '../../helpers/formatter.js'
import PropTypes from 'prop-types'

import {ReactComponent as AlgorandSmall} from "../../assets/images/algorand_logo_mark_black.svg"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

const EligibleGovernors = (props) => {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [activeAll, setActiveAll] = useState(true);
  const [activeEligible, setActiveEligible] = useState(false);
  const [activeIneligible, setActiveIneligible] = useState(false);

  const [pageOptions, setPageOptions] = useState({});
  const [productData, setProductData] = useState([]);

  const [searchSettings, setSearchSettings] = useState({});

   const [tooltipDefi, setTooltipDefi] = useState(false);

  let pathname = location.pathname
  let loc = pathname.slice(-1) === "/" ? pathname.substring(0, pathname.length - 1) : pathname.substring(0, pathname.lastIndexOf('/'))
  if (loc === "") {
    loc = props.period;
  }

  const getStatus = (cellContent, row) => (
    <Badge
      className={row.status === 1 ? "font-size-12 badge-soft-success"  : "font-size-12 badge-soft-danger"}
      color={row.status === 1 ? "success" : "danger"}
      pill
    >
      {row.status === 1 ? "Eligible" : "Ineligible"}
    </Badge>
  );

  const getAccountAddress = (cellContent, row) => (
    <a href={loc + "/account/" + row.accountAddress}>{row.friendlyAccountAddress}</a>
  );

  const getCommittedAlgos = (cellContent, row) => (
    <>
      {row.committedAlgos}
      <AlgorandSmall className="icon-style"/>
    </>
  );

  const getId = (cellContent, row) => (
    <>
      <span className={row.status === 1 ? "" : "text-danger"}>{row.id}</span>
    </>
  );

  const getUseDefi = (cellContent, row) => (
    <>
      {row.useDefi && (
        <>
          <Tooltip
                placement="top"
                isOpen={tooltipDefi}
                target={"tooltipDefiPlacement-" + row.id}
                toggle={() => {
                  setTooltipDefi(!tooltipDefi);
                }}
                >
                {row.committed_algo_amount_for_extra_rewards}
          </Tooltip>
          <i style={{marginLeft:4}} className="fas fa-check" id={"tooltipDefiPlacement-" + row.id}/>
        </>
      )}
    </>
  );

  const getVoting = (dataf, textf, cellContent, row) => (
    <>
    {row[dataf] && row[dataf].split(',').map((item, i) => (
      <Badge
        key={i}
        style = {{ marginRight : 1 }}
        className={(i === 0 ? "font-size-12 badge-soft-info"  : (i === 1 ? "font-size-12 badge-soft-success" : "font-size-12 badge-soft-warning")) + (item === "0" ? " collapse" : "")}
        color={i === 0 ? "info" : (i === 1 ? "success" : "warning")}
        pill
      >
        {i === 0 ? "A" : "B"}
      </Badge>
    ))}
    </>
  );

  const defaultColumn = [{
    dataField: 'id',
    text: 'Id',
    sort: false,
    formatter: getId
  },
  {
    dataField: 'useDeFi',
    text: 'DeFi',
    sort: false,
    formatter: getUseDefi
  },
  {
    dataField: 'committedAlgos',
    text: 'Committed Algos',
    sort: true,
    formatter: getCommittedAlgos
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: false,
    formatter: getStatus
  },
  {
    dataField: 'accountAddress',
    text: 'Account Address',
    sort: false,
    formatter: getAccountAddress
  }, {
    dataField: 'registrationDate',
    text: 'Registration Date',
    sort: true
  }, {
    dataField: 'notEligibleDatetime',
    text: 'Ineligible Date',
    sort: true
  }];

  const [columns, setColumns] = useState(defaultColumn);

  useEffect(() => {


    const fetchData = async () => {

      setLoading(true);
      try {
        if (searchSettings.sortField)
        {
          let typ = "all";
          if (activeEligible) {
            typ = "eligible";
          } else if (activeIneligible) {
            typ = "ineligible";
          }
          const response = await fetch((props.remote ? "https://www.tryalgorand.com/api" : "http://localhost:8080") + "/governance/"+props.period+"/"+typ+"/"+searchSettings.sortField+"/"+searchSettings.sortOrder+"/"+searchSettings.page+"");
          const jsonData = await response.json();

          if (jsonData.totalSize)
          {
            let url = "https://api.nf.domains/nfd/address?limit=1&view=tiny&address=X5KD3VTDUN3H2FFYJX2NDIGQ2V2SS44XUSYGJWHI4KH6ZOXF44SE7KEXVU"
            for (let i = 0; i < jsonData.results.length; i++) {
              url += "&address=" + jsonData.results[i]["address"];
            }

            const response1 = await fetch(url);
            const jsonData1 = await response1.json();
            var lookupDict = {};
            for (let i = 0; i < jsonData1.length; i++) {
              let firs = jsonData1[i];
              lookupDict[firs["caAlgo"][0]] = firs["name"]
            }
            for (let i = 0; i < jsonData.results.length; i++) {
              let adr = jsonData.results[i]["address"]
              jsonData.results[i]["friendlyAccountAddress"] = lookupDict[adr]  === undefined ? adr : lookupDict[adr]
            }
          }

          setData(jsonData);
        }
      } catch (error) {
        console.error(error)
      }
      setLoading(false);
    };

    fetchData();
  }, [searchSettings]);

  useEffect(() => {
    if (!loading && !props.foundationPeriod.loading)
    {

      const copy = [...defaultColumn];

      for (var j = props.foundationPeriod.data.voting_sessions.length; j > 0; j--) {
        for (var k = props.foundationPeriod.data.voting_sessions[j-1]["topic_count"]; k > 0; k--) {
          let dataf = props.foundationPeriod.data.voting_sessions[j-1]["id"] + "-" + k.toString();
          let textf = 'V' + j.toString() + "-" + "M" + k.toString();
          copy.splice(3, 0,
          {
              dataField: dataf,
              text: textf,
              sort: false,
              formatter: (cellContent, row) => getVoting(dataf, textf, cellContent, row)
          });
        }
      }


      setColumns(copy);

      if (data.totalSize)
      {

        let newArray = [];
        for (let i = 0; i < data.results.length; i++) {
          let item = data.results[i];
          let num = (item["committed_algo_amount"] + item["committed_assets_amount_in_algo"]) / 1000000;
          let max = num < 1 ? 6 : 0;

          let dataObj = {
            "id": (searchSettings.page - 1)*10 + i+1,
            "useDefi": item["committed_algo_amount_for_extra_rewards"] > 0,
            "committed_assets_amount_in_algo" : new Intl.NumberFormat('en-US', {maximumFractionDigits: max}).format(item["committed_assets_amount_in_algo"] / 1000000),
            "committed_algo_amount_for_extra_rewards": item["committed_algo_amount_for_extra_rewards"],
            "committedAlgos": new Intl.NumberFormat('en-US', {maximumFractionDigits: max}).format(num),
            "status": item["is_eligible"],
            "accountAddress": item["address"],
            "friendlyAccountAddress": item["friendlyAccountAddress"],
            "registrationDate": formatDateLong(new Date(item["registration_datetime"])),
            "notEligibleDatetime": item["not_eligible_datetime"] ? formatDateLong(new Date(item["not_eligible_datetime"])) : "-"
          }
          if (item["vote_ids"] != null) {
            let lastvoteId = 0
            let topicIndex = 1
            let voteids = item["vote_ids"].split(",");
            let vote_a_values = item["vote_a_values"].split(",");
            let vote_b_values = item["vote_b_values"].split(",");
            for (let m = 0; m < voteids.length; m++) {
              let voteid = voteids[m].split("-")
              if (voteid[0] != lastvoteId) {
                lastvoteId = voteid[0]
                topicIndex = 1
              }
              let a_value = vote_a_values[m] == 0 ? 0 : 1;
              let b_value = vote_b_values[m] == 0 ? 0 : 1;
              dataObj[voteid[0] + "-" + topicIndex] = a_value.toString() + "," + b_value.toString();
              topicIndex = topicIndex + 1
            }
          }
          //""6-6,6-7""

          newArray.push(dataObj);
        }
        setProductData(newArray);
        setPageOptions({
          sizePerPage: 10,
          totalSize: data.totalSize, // replace later with size(customers),
          page: searchSettings.page,
          custom: true,
          paginationSize : 3
        });
      }
      else {
        setProductData([]);
        setPageOptions({
          sizePerPage: 10,
          totalSize: 0, // replace later with size(customers),
          page: searchSettings.page,
          custom: true,
          paginationSize : 3
        });
      }
    }

  }, [data, loading, props.foundationPeriod]);

  useEffect(() => {
    setPageOptions({
      sizePerPage: 10,
      totalSize: 0, // replace later with size(customers),
      page: 1,
      custom: true,
    });
  }, []);

  const defaultSorted = [{
    dataField: 'committedAlgos',
    order: 'asc'
  }];

  const onTableChange = (type, newState) => {
    // handle any data change here
    setSearchSettings({sortOrder:newState.sortOrder, sortField:newState.sortField, page:newState.page});
    //setPageNumber(2);
    //Update();
      //foundationPeriod = useFetchData("http://localhost:8080/governance/committedAlgos/asc/1");
  }

  const updateActiveAll = () => {
    setSearchSettings({sortOrder:searchSettings.sortOrder, sortField:searchSettings.sortField, page:1});
    setActiveAll(true);
    setActiveEligible(false);
    setActiveIneligible(false);
  };

  const updateActiveEligible = () => {
    setSearchSettings({sortOrder:searchSettings.sortOrder, sortField:searchSettings.sortField, page:1});
    setActiveAll(false);
    setActiveEligible(true);
    setActiveIneligible(false);
  };

  const updateActiveIneligible = () => {
    setSearchSettings({sortOrder:searchSettings.sortOrder, sortField:searchSettings.sortField, page:1});
    setActiveAll(false);
    setActiveEligible(false);
    setActiveIneligible(true);
  }
  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  return (
    <React.Fragment>
        <Card>
          <CardBody>
            <p className="text-muted mb-4">
              <i
                className="mdi mdi-account-multiple h2 text-secondary align-middle mb-0 me-3"
              />{" "}
              Governors List
            </p>
            <div className="toolbar d-flex flex-wrap justify-content-center">
              <Button
                color={activeAll ? "primary" : "light"}
                type="button"
                onClick={() => updateActiveAll()}
                id="all"
              >
                All
              </Button>
              <Button
                color={activeEligible ? "primary" : "light"}
                type="button"
                onClick={() => updateActiveEligible()}
                id="eligible"
              >
                Eligible
              </Button>
              <Button
                color={activeIneligible ? "primary" : "light"}
                type="button"
                onClick={() => updateActiveIneligible()}
                id="Ineligible"
              >
                Ineligible
              </Button>
            </div>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField='id'
              columns={columns}
              data={productData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField='id'
                  columns={columns}
                  data={productData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              remote
                              keyField={"id"}
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              onTableChange={ onTableChange }
                              classes={
                                "table align-middle table-nowrap"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />

                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">

                          <div className="text-md-right ms-auto mt-2">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )
                  }
                </ToolkitProvider>
              )
              }</PaginationProvider>
          </CardBody>
        </Card>
    </React.Fragment>
  )
}

EligibleGovernors.propTypes = {
  foundationPeriod: PropTypes.object,
  period: PropTypes.string,
  remote: PropTypes.bool
}

export default EligibleGovernors
