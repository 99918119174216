import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, Button, Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from 'prop-types'

const CommittedStakePerDate = (props) => {
    const [btnprimary1, setBtnprimary1] = useState(false);

    const [tooltipOVerviewPerDate, settooltipOVerviewPerDate] = useState(false);

    const [governanceData, setGovernanceData] = useState({
        title: "Governance",
        tooltip: " All committed ALGOs (General and DeFi).",
        index: 1
    });

    const [generalData, setGeneralData] = useState({
        title: "General Governance",
        tooltip: "All committed ALGOs excluding DeFi commitments.",
        index: 2
    });

    const [defiData, setDefiData] = useState({
        title: "DeFi Governance",
        tooltip: "All committed ALGOs via the different DeFi platforms (such as Vault, Liquid Governance and Liquidity provider).",
        index: 3
    });

    const [lgData, setLgData] = useState({
        title: "Liquid Governance",
        tooltip : "Liquid Governance is a unique feature that allows ALGOs holders to participate in the Algorand Governance while maintaining their liquidity.",
        index : 4
    });

    const [lpData, setLpData] = useState({
        title: "Liquidity Provider Governance",
        tooltip : "Committed DEX LP tokens in ALGOs value.",
        index : 5
    });

    const [secondData, setSecondData] = useState({
        is_loaded: false
    });

    const [options, setOptions] = useState({
        chart: {
            id: '456', group: 'perDate', toolbar: {
                show: false, tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }
        },
        dataLabels: {enabled: !1},
        stroke: {curve: "smooth", width: 2},
        markers: {size: 0, style: "hollow"},
        xaxis: {
            title: {text: "Date"},
            type: "datetime",
            tickAmount: 6,
        },
        yaxis: {
            title: {text: "Number of ALGOs"},
            labels: {
                formatter: function (value) {
                    let val = Math.abs(value)
                    if (val >= 1000000000) {
                        val = (val / 1000000000).toFixed(2) + ' Bn'
                    } else if (val >= 1000000) {
                        val = (val / 1000000).toFixed(0) + ' M'
                    } else if (val >= 1000) {
                        val = (val / 1000).toFixed(0) + ' K'
                    } else {
                        val = (val).toFixed(0)
                    }
                    return val
                },
                minWidth: 40
            },
            tickAmount: 5,
        },
        tooltip: {
            x: {show: false}, y: {
                formatter: function (value) {
                    var val = Math.abs(value)
                    return val.toLocaleString('en-US', {maximumFractionDigits: 0})
                }
            }, theme: false
        },
        colors: ["#556ee6"],

    });

    const [options1, setOptions1] = useState({
        chart: {
            toolbar: {
                show: false, tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }, id: '123', group: 'perDate',
        },
        dataLabels: {enabled: !1},
        stroke: {curve: "smooth", width: 2},
        markers: {size: 0, style: "hollow"},
        xaxis: {
            title: {text: "Date"},
            type: "datetime",
            tickAmount: 6,
        },
        yaxis: {
            title: {text: "Number of governors"},
            labels: {
                show: true,
                formatter: function (value) {
                    var val = Math.abs(value)
                    if (val >= 1000000000) {
                        val = (val / 1000000000).toFixed(0) + ' Bn'
                    } else if (val >= 1000000) {
                        val = (val / 1000000).toFixed(0) + ' M'
                    } else if (val >= 1000) {
                        val = (val / 1000).toFixed(0) + ' K'
                    } else {
                        val = (val).toFixed(0)
                    }
                    return val
                },
                minWidth: 40
            },
            tickAmount: 5,
        },
        tooltip: {
            x: {show: false}, y: {
                formatter: function (value) {
                    let val = Math.abs(value)
                    return val.toLocaleString('en-US', {maximumFractionDigits: 0})
                },
            }, theme: false
        },
        colors: ["#34c38f"]
    });

    const refreshFilter = () => {
      if (activeY) {
          updateChartActive();
      }
      else if (active6M) {
          updateChartSixMonth();
      }
      else if (activeM) {
          updateChartLastWeek()
      }
      else if(activeA) {
          updateChartAll();
      }
    };

    const onGovernanceClick = () => {
        setSecondData(governanceData);
    };

    const onGeneralClick = () => {
        setSecondData(generalData);
    };

    const onDefiClick = () => {
        setSecondData(defiData);
    };

    const onLiquidClick = () => {
        setSecondData(lgData);
    };

    const onLpClick = () => {
        setSecondData(lpData);
    };

    const [activeM, setactiveM] = useState(false);
    const [active6M, setactive6M] = useState(false);
    const [activeY, setactiveY] = useState(true);
    const [activeA, setactiveA] = useState(false);

    const getNewMinMaxYtwoSeries = (twoDSeries, twoDSeriesX, newMin, newMax) => {
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = twoDSeries.length - 1; i >= 0; i--) {
            if (twoDSeriesX[i] < newMin || twoDSeriesX[i] > newMax) continue;
            tmp = twoDSeries[i];
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
        }
        return {lowest, highest}
    };

    const getNewMinMaxY = (series, newMin, newMax) => {
        var lowest = Number.POSITIVE_INFINITY;
        var highest = Number.NEGATIVE_INFINITY;
        var tmp;
        for (var i = series.length - 1; i >= 0; i--) {
            if (series[i][0] < newMin || series[i][0] > newMax) continue;
            tmp = series[i][1];
            if (tmp < lowest) lowest = tmp;
            if (tmp > highest) highest = tmp;
        }
        return {lowest, highest}
    };

    const updateChartLastWeek = () => {
        if (!secondData.committed_stake_series[0] || secondData.governors_series[0].data.length === 0) return;
        var newMax = new Date(props.foundationPeriod.data.end_datetime);
        var today = new Date();
        if (newMax >= today) {
            newMax = today;
        }
        var newMin = new Date();
        newMin.setDate(newMax.getDate() - 7);

        newMin = new Date(newMin.toDateString()).getTime();
        newMax = new Date(newMax.toDateString()).getTime();

        const {lowest, highest} = getNewMinMaxY(secondData.committed_stake_series[0].data, newMin, newMax);
        let opt = getModifiedOptionsY(options, lowest * 0.98, highest * 1.02);

        let obj = getNewMinMaxY(secondData.governors_series[0].data, newMin, newMax);
        let opt1 = getModifiedOptionsY(options1, obj.lowest * 0.98, obj.highest * 1.02);

        setOptions(getModifiedOptions(opt, newMin, newMax));
        setOptions1(getModifiedOptions(opt1, newMin, newMax));

        setactiveM(true);
        setactive6M(false);
        setactiveY(false);
        setactiveA(false);
    };

    const updateChartSixMonth = () => {

        if (!secondData.committed_stake_series[0] || secondData.governors_series[0].data.length === 0) return;

        var newMax = new Date(props.foundationPeriod.data.end_datetime);
        var today = new Date();
        if (newMax >= today) {
            newMax = today;
        }
        var newMin = new Date();
        newMin.setMonth(newMax.getMonth() - 1);

        newMin = newMin.getTime();
        newMax = newMax.getTime();

        const {lowest, highest} = getNewMinMaxY(secondData.committed_stake_series[0].data, newMin, newMax);
        let opt = getModifiedOptionsY(options, lowest * 0.98, highest * 1.02);

        let obj = getNewMinMaxY(secondData.governors_series[0].data, newMin, newMax);
        let opt1 = getModifiedOptionsY(options1, obj.lowest * 0.98, obj.highest * 1.02);

        setOptions(getModifiedOptions(opt, newMin, newMax));
        setOptions1(getModifiedOptions(opt1, newMin, newMax));

        setactiveM(false);
        setactive6M(true);
        setactiveY(false);
        setactiveA(false);
    };

    const updateChartActive = () => {
        if (!secondData.committed_stake_series[0] || secondData.governors_series[0].data.length === 0) return;
        let newMin = new Date(props.foundationPeriod.data.start_datetime);
        let newMax = new Date(props.foundationPeriod.data.end_datetime);
        let today = new Date();
        if (newMax >= today) {
            newMax = today;
        }

        newMin = newMin.getTime();
        newMax = newMax.getTime();

        let {lowest, highest} = getNewMinMaxY(secondData.committed_stake_series[0].data, newMin, newMax);
        let opt = getModifiedOptionsY(options, lowest * 0.98, highest * 1.02);

        let obj = getNewMinMaxY(secondData.governors_series[0].data, newMin, newMax);
        let opt1 = getModifiedOptionsY(options1, obj.lowest * 0.98, obj.highest * 1.02);

        let newOption = getModifiedOptions(opt, newMin, newMax);
        let newOption1 = getModifiedOptions(opt1, newMin, newMax);

        setOptions(newOption);
        setOptions1(newOption1);

        setactiveM(false);
        setactive6M(false);
        setactiveY(true);
        setactiveA(false);
    };

    const updateChartAll = () => {
        if (!secondData.committed_stake_series[0] || secondData.governors_series[0].data.length == 0) return;
        let newMin = new Date(props.foundationPeriod.data.start_datetime).getTime();
        let newMax = new Date(props.foundationPeriod.data.end_datetime).getTime();

        const {lowest, highest} = getNewMinMaxY(secondData.committed_stake_series[0].data, newMin, newMax);
        let opt = getModifiedOptionsY(options, lowest * 0.98, highest * 1.02);

        let obj = getNewMinMaxY(secondData.governors_series[0].data, newMin, newMax);
        let opt1 = getModifiedOptionsY(options1, obj.lowest * 0.98, obj.highest * 1.02);

        setOptions(getModifiedOptions(opt, newMin, newMax));
        setOptions1(getModifiedOptions(opt1, newMin, newMax));

        setactiveM(false);
        setactive6M(false);
        setactiveY(false);
        setactiveA(true);
    };

    const getModifiedOptions = (oldOptions, newMin, newMax) => {
        const modifiedOptions = {
            ...oldOptions,
            xaxis: {
                ...oldOptions.xaxis,
                min: newMin,
                max: newMax,
            },
        };
        return modifiedOptions;
    }

    const getModifiedOptionsY = (oldOptions, newMin, newMax) => {
        const modifiedOptions = {
            ...oldOptions,
            yaxis: {
                ...oldOptions.yaxis,
                min: newMin,
                max: newMax,
            },
        };
        return modifiedOptions;
    }

    const updateDataOptions = (oldOptions, updateOptions) => {
        return {...oldOptions, ...updateOptions};
    }

    function convertUTCDateToLocalDate(date) {
        let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        let offset = date.getTimezoneOffset() / 60;
        let hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    useEffect(() => {
        if (!props.overviewPeriod.loading && !props.foundationPeriod.loading) {
            let dataArray = [];
            let general_dataArray = []
            let defi_dataArray = [];
            let lg_dataArray = [];
            let lp_dataArray = [];

            let dataArrayGovernor = [];
            let general_dataArrayGovernor = [];
            let defi_dataArrayGovernor = [];
            let lg_dataArrayGovernor = [];
            let lp_dataArrayGovernor = [];

            let datetime = new Date(props.foundationPeriod.data.start_datetime);
            datetime = convertUTCDateToLocalDate(datetime)

            let committedStakePerDay = props.overviewPeriod.data.committed_stake.interval_per_day;
            let defi_committedStakePerDay = props.overviewPeriod.data.defi_committed_stake.interval_per_day;
            let lp_committedStakePerDay = props.overviewPeriod.data.assets_committed_stake.interval_per_day;

            let governorPerDay = props.overviewPeriod.data.governor.interval_per_day;
            let defi_governorPerDay = props.overviewPeriod.data.defi_governor.interval_per_day;
            let lp_governorPerDay = props.overviewPeriod.data.assets_governor.interval_per_day;

            let total = 0;
            let general_total = 0;
            let defi_total = 0;
            let lg_total = 0;
            let lp_total = 0;

            let totalGovernor = 0;
            let general_totalGovernor = 0;
            let defi_totalGovernor = 0;
            let lg_totalGovernor = 0;
            let lp_totalGovernor = 0;

            for (let i = 0; i < committedStakePerDay.length; i++) {
                total += (committedStakePerDay[i] / 1000000);
                defi_total += (defi_committedStakePerDay[i] / 1000000);
                general_total += (committedStakePerDay[i] - defi_committedStakePerDay[i]) / 1000000;
                lp_total += (lp_committedStakePerDay[i] / 1000000);
                lg_total += (defi_committedStakePerDay[i] - lp_committedStakePerDay[i]) / 1000000;

                totalGovernor += governorPerDay[i];
                defi_totalGovernor += defi_governorPerDay[i];
                general_totalGovernor += (governorPerDay[i] - defi_governorPerDay[i]);
                lp_totalGovernor += lp_governorPerDay[i];
                lg_totalGovernor += (defi_governorPerDay[i] - lp_governorPerDay[i]);

                dataArray.push([datetime.getTime(), total]);
                general_dataArray.push([datetime.getTime(), general_total]);
                defi_dataArray.push([datetime.getTime(), defi_total]);
                lg_dataArray.push([datetime.getTime(), lg_total]);
                lp_dataArray.push([datetime.getTime(), lp_total]);

                dataArrayGovernor.push([datetime.getTime(), totalGovernor]);
                general_dataArrayGovernor.push([datetime.getTime(), general_totalGovernor]);
                defi_dataArrayGovernor.push([datetime.getTime(), defi_totalGovernor]);
                lg_dataArrayGovernor.push([datetime.getTime(), lg_totalGovernor]);
                lp_dataArrayGovernor.push([datetime.getTime(), lp_totalGovernor]);

                let tomorrow = new Date(datetime);
                tomorrow.setDate(datetime.getDate() + 1);
                tomorrow.setHours(23, 59, 59, 999);
                let today = new Date();
                if (tomorrow >= today) {
                    tomorrow = today;
                }

                datetime = tomorrow
            }

            setGovernanceData(updateDataOptions(governanceData, {
                committed_stake_series: [
                    {
                        name: "ALGOs",
                        data: dataArray,
                    },
                ],
                governors_series: [
                    {
                        name: "Governors",
                        data: dataArrayGovernor,
                    },
                ],
                is_loaded: true
            }));

            setGeneralData(updateDataOptions(generalData, {
                committed_stake_series: [
                    {
                        name: "ALGOs",
                        data: general_dataArray,
                    },
                ],
                governors_series: [
                    {
                        name: "Governors",
                        data: general_dataArrayGovernor,
                    },
                ],
                is_loaded: true
            }));

            setDefiData(updateDataOptions(defiData, {
                committed_stake_series: [
                    {
                        name: "ALGOs",
                        data: defi_dataArray,
                    },
                ],
                governors_series: [
                    {
                        name: "Governors",
                        data: defi_dataArrayGovernor,
                    },
                ],
                is_loaded: true
            }));
            setLgData(updateDataOptions(lgData, {
                committed_stake_series: [
                    {
                        name: "ALGOs",
                        data: lg_dataArray,
                    },
                ],
                governors_series: [
                    {
                        name: "Governors",
                        data: lg_dataArrayGovernor,
                    },
                ],
                is_loaded: true
            }));
            setLpData(updateDataOptions(lpData, {
                committed_stake_series: [
                    {
                        name: "ALGOs",
                        data: lp_dataArray,
                    },
                ],
                governors_series: [
                    {
                        name: "Governors",
                        data: lp_dataArrayGovernor,
                    },
                ],
                is_loaded: true
            }));
        }
    }, [props.overviewPeriod.loading, props.foundationPeriod.loading])

    useEffect(() => {
        setSecondData(governanceData);
    }, [governanceData])


    useEffect(() => {
        if (secondData.is_loaded) {
            refreshFilter();
        }
    }, [secondData])

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [props.refresh])

    return (
        <React.Fragment>
            {secondData.is_loaded && (secondData.committed_stake_series[0].data.length > 0 || secondData.governors_series[0].data.length > 0) && (
                <Card>
                    <CardBody>
                        <p className="text-muted mb-4">
                            <i
                                className="mdi mdi-chart-line h2 text-info align-middle mb-0 me-3"
                            />{" "}
                            Overview Per Date
                        </p>
                        <div>
                            <div id="overview-chart" className="apex-charts" dir="ltr">
                                <div className="d-flex flex-wrap justify-content-center">
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOVerviewPerDate}
                                        target="tooltipOVerviewPerDateooltipTop"
                                        toggle={() => {
                                            settooltipOVerviewPerDate(!tooltipOVerviewPerDate);
                                        }}
                                    >
                                        {secondData.tooltip}
                                    </Tooltip>
                                    <Dropdown
                                        isOpen={btnprimary1}
                                        toggle={() => setBtnprimary1(!btnprimary1)}
                                        className="text-center"
                                        style={{marginLeft: 10, marginBottom: 3}}
                                    >
                                        <DropdownToggle caret
                                                        color="primary"
                                                        className="btn btn-primary">

                                            {secondData.title}  <i className="mdi mdi-chevron-down" />


                                        </DropdownToggle>
                                        <i style={{marginLeft: 4}} className="fas fa-info-circle"
                                           id="tooltipOVerviewPerDateooltipTop"/>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem className={secondData.index === 1 ? "active" : ""}
                                                          onClick={onGovernanceClick}>Governance</DropdownItem>
                                            <DropdownItem divider/>
                                            <DropdownItem className={secondData.index === 2 ? "active" : ""}
                                                          onClick={onGeneralClick}>&nbsp;&nbsp;&nbsp;&nbsp;General
                                                Governance</DropdownItem>
                                            <DropdownItem divider/>
                                            <DropdownItem className={secondData.index === 3 ? "active" : ""}
                                                          onClick={onDefiClick}>&nbsp;&nbsp;&nbsp;&nbsp;DeFi Governance</DropdownItem>
                                            <DropdownItem className={secondData.index === 4 ? "active" : ""}
                                                          onClick={onLiquidClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Liquid
                                                Governance</DropdownItem>
                                            <DropdownItem className={secondData.index === 5 ? "active" : ""}
                                                          onClick={onLpClick}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Liquidity
                                                Provider Governance</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                {props.overviewPeriod.loading && props.foundationPeriod.loading &&
                                    <p className="toolbar d-flex flex-wrap gap-2 justify-content-center placeholder-glow">
                                        <span className="placeholder col-4"></span></p>}
                                {!props.overviewPeriod.loading && !props.foundationPeriod.loading && (
                                    <div className="d-flex flex-wrap justify-content-center">
                                        <Button
                                            color={activeY ? "primary" : "light"}
                                            type="button"
                                            onClick={() => updateChartActive()}
                                            id="one_year"
                                        >
                                            ACTIVE
                                        </Button>
                                        <Button
                                            color={activeA ? "primary" : "light"}
                                            type="button"
                                            onClick={() => updateChartAll()}
                                            id="all"
                                        >
                                            ALL
                                        </Button>
                                        <Button
                                            color={activeM ? "primary" : "light"}
                                            type="button"
                                            onClick={() => updateChartLastWeek()}
                                            id="one_month"
                                        >
                                            1W
                                        </Button>
                                        <Button
                                            color={active6M ? "primary" : "light"}
                                            type="button"
                                            onClick={() => updateChartSixMonth()}
                                            id="six_months"
                                        >
                                            1M
                                        </Button>
                                    </div>
                                )}
                                <div id="overview-chart-timeline">
                                    <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                                        <p className="text-muted mt-4 mb-0">
                                            Committed stake
                                        </p>
                                    </div>
                                    {secondData.is_loaded && (
                                        <ReactApexChart
                                            options={options}
                                            series={secondData.committed_stake_series}
                                            type="line"
                                            height={350}
                                        />
                                    )}
                                </div>
                                <div id="overview-chart-timeline">
                                    <div className="toolbar d-flex flex-wrap gap-2 justify-content-center">
                                        <p className="text-muted mb-0">
                                            Governors
                                        </p>
                                    </div>
                                    {secondData.is_loaded && (
                                        <ReactApexChart
                                            options={options1}
                                            series={secondData.governors_series}
                                            type="line"
                                            height={350}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}
        </React.Fragment>
    );
};

CommittedStakePerDate.propTypes = {
    foundationPeriod: PropTypes.object,
    overviewPeriod: PropTypes.object,
    refresh: PropTypes.bool
}

export default CommittedStakePerDate;
