import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Row, Tooltip} from "reactstrap"
import PropTypes from 'prop-types'
import {ReactComponent as AlgorandSmall} from "../../../assets/images/algorand_logo_mark_black.svg";



const CommittedAlgos = (props) => {
  const [tooltipGeneralCommitted, setTooltipGeneralCommitted] = useState(false);
  const [tooltipDeFiCommitted, setTooltipDeFiCommitted] = useState(false);

  const [generalCommitment, setGeneralCommitment] = useState(0);
  const [lgCommitment, setLgCommitment] = useState(0);

  useEffect(() => {
    if (props.addressData === undefined || props.addressData["registration_datetime"] === "") return;

    let general = props.addressData["committed_algo_amount"] - props.addressData["committed_algo_amount_for_extra_rewards"];
    let lg = props.addressData["committed_algo_amount_for_extra_rewards"] - props.addressData["committed_assets_amount_in_algo"];

    setGeneralCommitment(general)
    setLgCommitment(lg)
  });

  return (
      <React.Fragment>
        <Card>
          <CardBody>
            <p className="text-muted mb-4">
              <i
                  className="mdi mdi-circle-multiple h2 text-info align-middle mb-0 me-3"
              />{" "}
              Committed ALGOs
            </p>
            <Row>
              <Col xs="12">
                <div className="text-center m-2">
                  <h5>
                    {props.addressData === undefined || props.addressData["registration_datetime"] === "" ? "0" : new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format((props.addressData["committed_algo_amount"] + props.addressData["committed_assets_amount_in_algo"]) / 1000000)}
                    <AlgorandSmall className="icon-style"/>
                  </h5>
                  <div>
                        <span>{props.addressData === undefined || props.addressData["registration_datetime"] === "" ? "0" : new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format((generalCommitment) / 1000000)}
                          <AlgorandSmall className="icon-style" style={{height: 24}}/>
                                    </span>
                    <Tooltip
                        placement="top"
                        isOpen={tooltipGeneralCommitted}
                        target="targetTooltipGeneralCommitted"
                        toggle={() => {
                          setTooltipGeneralCommitted(!tooltipGeneralCommitted);
                        }}
                    >
                      All committed ALGOs excluding DeFi commitments.
                    </Tooltip>
                    General <i className="fas fa-info-circle" id="targetTooltipGeneralCommitted"/>
                  </div>

                  <div>
                        <span>{props.addressData === undefined || props.addressData["registration_datetime"] === "" ? "0" : new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format((props.addressData["committed_algo_amount_for_extra_rewards"]) / 1000000)}
                          <AlgorandSmall className="icon-style" style={{height: 24}}/>
                                    </span>
                    <Tooltip
                        placement="top"
                        isOpen={tooltipDeFiCommitted}
                        target="targetTooltipDeFiCommitted"
                        toggle={() => {
                          setTooltipDeFiCommitted(!tooltipDeFiCommitted);
                        }}
                    >
                      <p>All committed ALGOs via the different DeFi platforms (such as Vault, Liquid Governance and Liquidity provider).</p>

                      Liquid Governance: {lgCommitment / 1000000 } <AlgorandSmall className="icon-style" style={{height: 24}}/> {"\n\n"}
                      Liquidity Provider: {lgCommitment / 1000000 }
                    </Tooltip>
                    DeFi <i className="fas fa-info-circle" id="targetTooltipDeFiCommitted"/>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
  );
}

CommittedAlgos.propTypes = {
  addressData: PropTypes.object
}

export default CommittedAlgos;
